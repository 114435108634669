import React from "react";
import {store} from "react-notifications-component";
import strings from "../lang/strings";
import {requestGet, requestPost} from "../helper/util";
import {RESULT_CODE} from "../config/const";

export default class BaseApp extends React.Component {
    constructor(props) {
        super(props);

        this.toggleMobileSidebar = () => {
            this.setState(state => ({
                pageSidebarToggled: !state.pageSidebarToggled
            }));
        }
        this.handleSetPageSidebar = (value) => {
            this.setState({
                pageSidebar: value
            });
        }
        this.handleSetPageHeader = (value) => {
            this.setState({
                pageHeader: value
            });
        };
        this.handleSetPageBoxedLayout = (value) => {
            if (value === true) {
                document.body.classList.add('boxed-layout');
            } else {
                document.body.classList.remove('boxed-layout');
            }
        }
        this.toggleLoadingBar = isLoading => {
            this.setState({pageLoading: isLoading});
        }

        this.showModal = (title, text, okBtn, cancelBtn, cb) => {
            this.setState({
                modal: {
                    show: true,
                    title: title,
                    text: text,
                    subText: '',
                    okBtn: okBtn,
                    cancelBtn: cancelBtn,
                    cb: cb
                }
            });
        }

        this.showToast = (type, message) => {
            store.addNotification({
                title: strings.notify,
                message: message,
                type: type,
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true
                }
            });
        }

        this.showAlert = (text, cb, okBtn = strings.modal.confirm) => {
            this.showModal(strings.modal.alert, text, okBtn, '', cb);
        }
        this.showConfirm = (text, cb, okBtn = strings.modal.ok, cancelBtn = strings.modal.cancel) => {
            this.showModal(strings.modal.confirm, text, okBtn, cancelBtn, cb);
        }
        this.post = async (uri, params, cb, showProgress = false) => {
            await this.request('POST', uri, params, cb, showProgress);
        }
        this.get = async (uri, params, cb, showProgress = false) => {
            await this.request('GET', uri, params, cb, showProgress);
        }
        this.request = async (method, uri, params, cb, showProgress = false) => {
            if (showProgress) {
                this.toggleLoadingBar(true);
            }

            let token = this.props.rootStore.getToken();

            try {
                let response = method === 'POST' ?
                    await requestPost(uri, params, token)
                    :
                    await requestGet(uri, params, token);

                if (showProgress) {
                    this.toggleLoadingBar(false);
                }

                if (Number(response.code) === RESULT_CODE.SUCCESS) {
                    cb?.(response);
                } else {
                    this.handleApiError(response);
                }
            } catch (err) {
                if (showProgress) {
                    this.toggleLoadingBar(false);
                }

                this.showToast("danger", strings.server_error);
            }
        }

        this.handleApiError = e => {
            if (Number(e.code) === RESULT_CODE.TOKEN_EXPIRED ||
                Number(e.code) === RESULT_CODE.TOKEN_EXPIRED) {
                return this.props.history.replace('/login');
            }

            this.showToast("danger", e.msg);
        }

        this.convertToCsv = (fName, rows) => {
            var csv = '';
            for (var i = 0; i < rows.length; i++) {
                var row = rows[i];
                for (var j = 0; j < row.length; j++) {
                    var val = row[j] === null ? '' : row[j].toString();
                    val = val.replace(/\t/gi, " ");
                    if (j > 0)
                        csv += '\t';
                    csv += val;
                }
                csv += '\n';
            }

            // for UTF-16
            var cCode, bArr = [];
            bArr.push(255, 254);
            for (var i = 0; i < csv.length; ++i) {
                cCode = csv.charCodeAt(i);
                bArr.push(cCode & 0xff);
                bArr.push(cCode / 256 >>> 0);
            }

            var blob = new Blob([new Uint8Array(bArr)], { type: 'text/csv;charset=UTF-16LE;' });
            if (navigator.msSaveBlob) {
                navigator.msSaveBlob(blob, fName);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                    var url = window.URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", fName);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }
            }
        }

        this.state = {
            hasScroll: false,

            pageHeader: true,
            handleSetPageHeader: this.handleSetPageHeader,

            pageSidebar: true,
            pageSidebarToggled: false,
            handleSetPageSidebar: this.handleSetPageSidebar,
            toggleMobileSidebar: this.toggleMobileSidebar,

            handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

            pageLoading: false,
            toggleLoadingBar: this.toggleLoadingBar,

            convertToCsv: this.convertToCsv,

            modal: {
                show: false,
                title: '',
                text: '',
                subText: '',
                okBtn: '',
                cancelBtn: '',
                cb: null
            },
            showModal: this.showModal,
            showAlert: this.showAlert,
            showConfirm: this.showConfirm,
            showToast: this.showToast,

            post: this.post,
            get: this.get,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        this.setState({
            hasScroll: window.scrollY > 0
        });
        let elm = document.getElementsByClassName('nvtooltip');
        for (let i = 0; i < elm.length; i++) {
            elm[i].classList.add('d-none');
        }
    }

    onModalClose = res => {
        this.setState(state => ({
            modal: {...state.modal, show: false}
        }), () => this.state.modal.cb?.(res));
    }
}