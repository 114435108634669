import strings from "../../lang/strings";

export const AdminMenu = [
    {path: '/user', icon: 'fa fa-minus', title: strings.user_management},
    {path: '/teacher', icon: 'fa fa-minus', title: strings.teacher_management},
    {path: '/virtualTeacher', icon: 'fa fa-minus', title: strings.virtual_teacher_management},
    {path: '/class', icon: 'fa fa-minus', title: strings.class_management},
    {path: '/schedule', icon: 'fa fa-minus', title: strings.class_schedule_management},
    {path: '/category', icon: 'fa fa-minus', title: strings.category_management},
]

export const TeacherMenu = [
    {path: '/class', icon: 'fa fa-minus', title: strings.class_management},
    {path: '/schedule', icon: 'fa fa-minus', title: strings.class_schedule_management},
]

export default {AdminMenu, TeacherMenu};
