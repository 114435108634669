import React from 'react';
import {Route, withRouter} from 'react-router-dom';
import {Redirect, Switch} from 'react-router';
import {inject, observer} from "mobx-react";

import routes from './../../config/page-route.jsx';
import strings from "../../lang/strings";

function setTitle(path, routeArray) {
    const pageTitle = routeArray.find(it => it.path === path)?.title;
    document.title = (pageTitle) ? pageTitle : strings.admin;
}

class Content extends React.Component {
    componentDidMount() {
        this.props.history.listen(() => {
            setTitle(this.props.history.location.pathname, routes);
        });

        setTitle(this.props.history.location.pathname, routes);
    }

    render() {
        return (
            <div className={'content'}>
                <Switch>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={
                                !route.private || this.props.rootStore.getToken() ? ((this.props.rootStore.getType() != "admin" && (route.path == "/user" || route.path == "/category" || route.path == "/teacher")) ? () =>
                                    <Redirect to='/login'/> : route.component) : () => <Redirect to='/login'/>
                            }
                        />
                    ))}
                    <Route component={() => <Redirect to='/'/>}/>
                </Switch>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(observer(Content)));
