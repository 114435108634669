import React from 'react';
import $ from 'jquery';
import DataTable from "../../components/control/data-table";
import strings from "../../lang/strings";
import {PageSettings} from "../../config/page-settings";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

class Category extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            categoryModal: false,
            start: 0,
            category: "",
            categoryUid: 0,
            categoryError: false,
        }
    }

    handleFormChange(e) {
        var self = this;
        self.setState({
            category: e.target.value
        });
    }

    refreshTable = (refresh) => {
        this.tblCategory.draw(refresh);
    };

    onCategoryModal(data) {
        this.setState({
            categoryUid: data.category_uid ?? 0,
            category: data.name ?? "",
            categoryError: false,
        });

        this.setState({categoryModal: !this.state.categoryModal})
    }

    onDeleteCategory(category_uid) {
        var self = this;
        this.context.showConfirm(strings.msg_confirm_delete_category, function (response) {
            if (response) {
                self.context.post("/deleteCategory", {
                    categoryUid: category_uid
                }, function (response) {
                    self.context.showToast("success", strings.msg_operation_success);

                    self.refreshTable();
                }, true);
            }
        })
    }

    onSaveCategory() {
        var self = this;
        if (self.state.category == "") {
            self.setState({
                categoryError: true
            });

            return;
        }

        self.context.post("/saveCategory", {
            categoryUid: self.state.categoryUid,
            category: self.state.category
        }, function (response) {
            self.context.showToast("success", strings.msg_operation_success);
            self.setState({
                categoryModal: false
            })
            self.refreshTable();
        }, true);
    }

    onCategoryCheck(categoryUid) {
        var self = this;

        self.context.post("/checkCategory", {
            categoryUid: categoryUid
        }, function (response) {
            self.context.showToast("success", strings.msg_operation_success);
            self.refreshTable();
        }, true);
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div className="row m-t-20">
                <div className="col-md-12 text-right">
                    <button type="button" className="btn btn-primary m-r-5 m-b-5"
                            onClick={() => this.onCategoryModal(0)}>{strings.category_add}</button>
                </div>
                <div className="col-md-12">
                    <DataTable ref={(ref) => this.tblCategory = ref}
                               customPaginate={true}
                               initialPage={0}
                               hideTotalCount={true}
                               onPageChange={(value) => {
                                   self.state.start = value;
                                   this.refreshTable();
                               }}
                               options={{
                                   columnDefs: [
                                       {targets: [columnNo++], data: 'name', orderable: true,},
                                       {targets: [columnNo++], data: 'reg_dt', orderable: true,},
                                       {targets: [columnNo++], data: 'creator', orderable: true,},
                                       {
                                           targets: [columnNo++],
                                           data: 'status',
                                           orderable: true,
                                           render(data, type, full, meta) {
                                               return data == "checking" ? strings.checking : strings.ok;
                                           }
                                       },
                                       {
                                           targets: [columnNo++],
                                           data: 'class_cnt',
                                           orderable: true,
                                       },
                                       {targets: [columnNo++], data: 'category_uid', orderable: false},
                                   ],
                                   order: [[1, 'DESC']],
                                   ajax: {
                                       url: '/getCategoryTableList',
                                       data: function (req) {
                                           req.columns = null;

                                           req.start = self.state.start;
                                           req.length = 10;
                                       }
                                   },
                                   drawCallback: function () {
                                   },
                                   fnCreatedRow: function (row, data, dataInd) {
                                       var editBtn = $(`<button type="button" id="edit" class="btn btn-primary m-r-5">${strings.edit}</button>`).on("click", function () {
                                           self.onCategoryModal(data);
                                       });

                                       var deleteBtn = $(`<button type="button" id="delete" class="btn btn-danger">${strings.delete}</button>`).on("click", function () {
                                           self.onDeleteCategory(data.category_uid);
                                       });

                                       $('td:last', row).html(editBtn);
                                       $('td:last', row).append(deleteBtn);

                                       if (data.status == "checking") {
                                           $('td:eq(3)', row).html(`
                                               <div style="display: flex; align-items: center;justify-content: space-between">
                                                    <span>${strings.checking}</span>
                                                    <button type="button" class="btn btn-default">${strings.do_check}</button>
                                               </div>
                                       `);

                                           $('td:eq(3)', row)
                                               .find('button')
                                               .on('click', function () {
                                                   self.onCategoryCheck(data.category_uid);
                                               });

                                       }
                                   }
                               }}>
                        <thead>
                        <tr>
                            <th>{strings.category_name}</th>
                            <th>{strings.reg_date}</th>
                            <th>{strings.creator}</th>
                            <th>{strings.checking}</th>
                            <th>{strings.using_class}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody/>
                    </DataTable>
                </div>
                <Modal className="modal-md" isOpen={this.state.categoryModal}
                       toggle={() => this.setState({categoryModal: !this.state.categoryModal})}>
                    <ModalHeader toggle={() => {
                        this.setState({categoryModal: !this.state.categoryModal})
                    }}>{strings.category_management}</ModalHeader>
                    <ModalBody>
                        <form className="">
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.category_name}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text" defaultValue={this.state.category}
                                           onChange={(e) => this.handleFormChange(e)}
                                           placeholder={strings.hint_category}/>
                                    {
                                        this.state.categoryError &&
                                        <p className="text-danger">{strings.msg_input_category}</p>
                                    }
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white"
                                onClick={() => this.setState({categoryModal: !this.state.categoryModal})}>{strings.cancel}</button>
                        <button className="btn btn-primary" onClick={() => this.onSaveCategory()}>{strings.add}</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default Category;