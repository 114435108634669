import React from 'react';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'reactstrap';
import strings from "../../../lang/strings";
import {inject} from "mobx-react";
import {checkValidEmail, getSignupType} from "../../../helper/util";
import {PageSettings} from "../../../config/page-settings";

class DropdownProfile extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
            passwordModalOpen: false,
            info: {
                currentPassword: "",
                newPassword: "",
                newPasswordConfirm: "",
            },
            error: {
                currentPassword: false,
                newPassword: false,
                newPasswordConfirm: false,
            }
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    logout() {
        this.props.rootStore.logout();
    }

    onChangePassword() {
        const {info} = this.state;
        const {error} = this.state;

        let self = this;

        error.currentPassword = info.currentPassword == "";
        error.newPassword = info.newPassword == "";
        error.newPasswordConfirm = info.newPasswordConfirm == "" || info.newPassword != info.newPasswordConfirm;

        this.setState({
            error: error
        });

        if (error.currentPassword || error.newPassword || error.newPasswordConfirm) {
            return;
        }

        self.context.post("/changePassword", {
            currentPassword: info.currentPassword,
            newPassword: info.newPassword,
        }, function (response) {
            self.context.showToast("success", strings.msg_operation_success);
            self.setState({
                passwordModalOpen: false
            });
        }, true);

    }

    withdraw() {
        const self = this;

        if(self.props.rootStore.getType() == "admin") {
            self.context.showToast("warning", strings.msg_impossible_delete_super_admin);
            return;
        }

        self.context.showConfirm(strings.msg_confirm_withdraw, function (response) {
            if (response) {
                self.context.post("/withdraw", {

                }, function (response) {
                    self.props.rootStore.logout();
                }, true);
            }
        });
    }

    handleFormChange(event) {
        const target = event.target;
        const name = target.name;

        const {info} = this.state;
        const {error} = this.state;

        info[name] = target.value;
        error[name] = target.value == "";

        this.setState({
            info: info,
            error: error
        });
    }

    onShowChangePasswordModal() {
        this.setState({
            passwordModalOpen: true
        });
    }

    render() {
        let self = this;
        return (
            <>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown navbar-user"
                          tag="li">
                    <DropdownToggle tag="a">
                        <i className="fa fa-cog m-r-20"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
                        <DropdownItem
                            onClick={() => this.onShowChangePasswordModal()}>{strings.change_password}</DropdownItem>
                        <DropdownItem onClick={(e) => this.logout()}>{strings.logout}</DropdownItem>
                        {/*{
                            this.props.rootStore.getType() != "admin" &&
                            <DropdownItem onClick={(e) => this.withdraw()}>{strings.withdraw}</DropdownItem>
                        }*/}
                    </DropdownMenu>
                </Dropdown>
                <Modal className="modal-md" isOpen={this.state.passwordModalOpen}
                       toggle={() => this.setState({passwordModalOpen: !this.state.passwordModalOpen})}>
                    <ModalHeader toggle={() => {
                        this.setState({passwordModalOpen: !this.state.passwordModalOpen})
                    }}>{strings.change_password}</ModalHeader>
                    <ModalBody>
                        <form className="form-horizontal">
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.current_password}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="currentPassword" type="password"
                                           required="required"
                                           onChange={(e) => this.handleFormChange(e)}/>
                                    {this.state.error.currentPassword &&
                                    <p className="text-danger">{strings.msg_input_current_password}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.new_password}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="newPassword" type="password"
                                           required="required"
                                           onChange={(e) => this.handleFormChange(e)}/>
                                    {this.state.error.newPassword &&
                                    <p className="text-danger">{strings.msg_input_new_password}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label
                                    className="col-sm-3 col-form-label text-right">{strings.new_password_confirm}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="newPasswordConfirm" type="password"
                                           required="required"
                                           onChange={(e) => this.handleFormChange(e)}/>
                                    {this.state.error.newPasswordConfirm &&
                                    <p className="text-danger">{strings.msg_confirm_password}</p>
                                    }
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white"
                                onClick={() => this.setState({passwordModalOpen: !this.state.passwordModalOpen})}>{strings.cancel}</button>
                        <button className="btn btn-primary"
                                onClick={() => this.onChangePassword()}>{strings.save}</button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default inject('rootStore')(DropdownProfile);
