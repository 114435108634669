import axios from "axios";
import { API_URL, HEADER } from "../config/const";
import strings from "../lang/strings";

export const requestGet = async (uri, args, token) => {
  const api = axios.create({
    baseURL: API_URL + '/admin/',
    headers: {
      [HEADER.AUTH_TOKEN]: token,
      [HEADER.LANG]: 'ko'
    }
  });

  const {data} = await api.get(uri, {params: args});
  return data;
};

export const requestPost = async (uri, args, token) => {
  const api = axios.create({
    baseURL: API_URL + '/admin/',
    headers: {
      [HEADER.AUTH_TOKEN]: token,
      [HEADER.LANG]: 'ko'
    }
  });

  const {data} = await api.post(uri, args);
  return data;
};

export const checkValidEmail = (email)=> {
  var reg = /^([A-Za-z0-9_\-\.]){1,}\@([A-Za-z0-9_\-\.]){1,}\.([A-Za-z]{2,4})$/;
  return reg.test(email) != false;
}

export const getSignupType = (type)=> {
  var signupType = strings.email_signup

  if (type === "kakao") {
    signupType = strings.kakao_signup;
  } else if (type === "google") {
    signupType = strings.google_signup;
  } else if (type === "apple") {
    signupType = strings.apple_signup
  }

  return signupType;
}
