import React from 'react';
import $ from 'jquery';
import DataTable from "../../components/control/data-table";
import strings from "../../lang/strings";
import {PageSettings} from "../../config/page-settings";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {checkValidEmail, getSignupType} from "../../helper/util";
import _ from "underscore";

class Teacher extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            teacherModal: false,
            userStart: 0,
            teacherStart: 0,
            addTeacherList: [],
            keyword: "",
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    refreshTeacherTable = (refresh) => {
        this.tblTeacher.draw(refresh);
    };

    refreshUserTable = (refresh) => {
        this.tblUser.draw(refresh);
    };


    onTeacherModal(uid) {
        this.addTeacherList = [];
        this.setState({teacherModal: !this.state.teacherModal})
    }

    onSelectUser(uid) {
        const e = _.find(this.addTeacherList, (i) => {
            return i == uid;
        });
        if (e) {
            this.addTeacherList = _.without(this.addTeacherList, e);
        } else {
            this.addTeacherList.push(uid);
        }
    }

    onChangeKeyword(event) {
        const target = event.target;
        this.state.keyword = target.value;
        this.state.userStart = 0;

        this.refreshUserTable();
    }

    onDeleteTeacher(uid) {
        var self = this;
        this.context.showConfirm(strings.msg_confirm_delete, function (response) {
            if (response) {
                self.context.post("/deleteTeacher", {
                    userUid: uid
                }, function (response) {
                    self.context.showToast("success", strings.msg_operation_success);

                    self.refreshTeacherTable();
                }, true);
            }
        })
    }

    onAddTeacher() {
        var self = this;

        if(self.addTeacherList.length == 0) {
            self.context.showToast("warning", strings.msg_select_user);
            return;
        }

        this.context.post("/addTeacher", {
            userList: self.addTeacherList
        }, function (response) {
            self.context.showToast("success", strings.msg_operation_success);

            self.refreshTeacherTable();
            self.setState({
                teacherModal: false
            })
        }, true);

    }

    render() {
        let self = this;
        let columnNo = 0;
        let tblUserColumnNo = 0;

        return (
            <div className="row m-t-20">
                <div className="col-md-12 text-right">
                    <button type="button" className="btn btn-primary m-r-5 m-b-5"
                            onClick={() => this.onTeacherModal(0)}>{strings.teacher_add}</button>
                </div>
                <div className="col-md-12">
                    <DataTable ref={(ref) => this.tblTeacher = ref}
                               customPaginate={true}
                               initialPage={0}
                               hideTotalCount={true}
                               onPageChange={(value) => {
                                   self.state.teacherStart = value;
                                   this.refreshTeacherTable();
                               }}
                               options={{
                                   columnDefs: [
                                       {targets: [columnNo++], data: 'name', orderable: true,},
                                       {targets: [columnNo++], data: 'class_name', orderable: false,},
                                       {targets: [columnNo++], data: 'reg_dt', orderable: true,},
                                       {targets: [columnNo++], data: 'last_login_dt', orderable: true,},
                                       {targets: [columnNo++], data: 'user_uid', orderable: false,},
                                   ],
                                   order: [[2, 'DESC']],
                                   ajax: {
                                       url: '/teacherTableList',
                                       data: function (req) {
                                           req.columns = null;
                                           req.start = self.state.teacherStart;
                                           req.length = 10;
                                       }
                                   },
                                   drawCallback: function () {
                                   },
                                   fnCreatedRow: function (row, data, index) {
                                       $('td:last', row).html("");
                                       $('td:last', row).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button`);

                                       $('td:last', row)
                                           .find('button:eq(0)')
                                           .on('click', function () {
                                               self.onDeleteTeacher(data.user_uid);
                                           });
                                   }
                               }}>
                        <thead>
                        <tr>
                            <th>{strings.name}</th>
                            <th>{strings.teaching_class}</th>
                            <th>{strings.reg_date}</th>
                            <th>{strings.last_login}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody/>
                    </DataTable>
                </div>
                <Modal className="modal-lg" isOpen={this.state.teacherModal}
                       toggle={() => this.setState({teacherModal: !this.state.teacherModal})}>
                    <ModalHeader toggle={() => {
                        this.setState({teacherModal: !this.state.teacherModal})
                    }}>{strings.teacher_add}</ModalHeader>
                    <ModalBody>
                        <div className="col-md-12">
                            <div className="input-group input-group-md m-b-10">
                                <div className="input-group-prepend"><span className="input-group-text"><i className="fa fa-search"></i></span></div>
                                <input type="text" id="keyword" className="form-control" placeholder=""
                                       onChange={(e) => this.onChangeKeyword(e)}/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <DataTable ref={(ref) => this.tblUser = ref}
                                       customPaginate={true}
                                       initialPage={0}
                                       hideTotalCount={true}
                                       onPageChange={(value) => {
                                           self.state.userStart = value;
                                           this.refreshUserTable();
                                       }}
                                       options={{
                                           columnDefs: [
                                               {targets: [tblUserColumnNo++], data: 'name', orderable: true,},
                                               {targets: [tblUserColumnNo++], data: 'email', orderable: true,},
                                               {
                                                   targets: [tblUserColumnNo++],
                                                   data: 'type',
                                                   orderable: false,
                                                   render: function (data, type, full, meta) {
                                                       var res = "";
                                                       if (data === "teacher") {
                                                           res = strings.teacher;
                                                       } else if (data === "enterprise") {
                                                           res = strings.enterprise + ">" + full.enterprise_name
                                                       } else {
                                                           res = strings.individual;
                                                       }
                                                       return res;
                                                   }
                                               },
                                               {targets: [tblUserColumnNo++], data: 'title', orderable: false,},
                                               {
                                                   targets: [tblUserColumnNo++],
                                                   data: 'sign_type',
                                                   orderable: false,
                                                   render: function (data, type, full, meta) {
                                                       return getSignupType(data);
                                                   }
                                               },
                                               {targets: [tblUserColumnNo++], data: 'reg_dt', orderable: true,},
                                               {targets: [tblUserColumnNo++], data: 'last_login_dt', orderable: true,},
                                               {
                                                   targets: [tblUserColumnNo++],
                                                   data: 'user_uid',
                                                   orderable: false,
                                               },
                                           ],
                                           order: [[5, 'DESC']],
                                           ajax: {
                                               url: '/userTableList',
                                               data: function (req) {
                                                   req.columns = null;
                                                   req.onlyUser = "y";
                                                   req.requestTeacher = "n";
                                                   req.start = self.state.userStart;
                                                   req.keyword = self.state.keyword;
                                                   req.length = 10;
                                               }
                                           },
                                           drawCallback: function () {
                                           },
                                           fnCreatedRow: function (row, data, dataInd) {
                                               $('td:last', row).html(`<input type="checkbox" class="form-check" value="${data}"/>`);

                                               $('td:last', row)
                                                   .find('input')
                                                   .on('click', function () {
                                                       self.onSelectUser(data.user_uid);
                                                   });
                                           }
                                       }}>
                                <thead>
                                <tr>
                                    <th>{strings.name}</th>
                                    <th>{strings.email}</th>
                                    <th>{strings.type}</th>
                                    <th>{strings.listening_class}</th>
                                    <th>{strings.login_type}</th>
                                    <th>{strings.reg_date}</th>
                                    <th>{strings.last_login}</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody/>
                            </DataTable>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white"
                                onClick={() => this.setState({teacherModal: !this.state.teacherModal})}>{strings.cancel}</button>
                        <button className="btn btn-primary" onClick={()=>this.onAddTeacher()}>{strings.ok}</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default Teacher;