module.exports = {
  API_URL: 'http://3.34.100.141/api',
  API_PATH: '/admin',

  HEADER: {
    AUTH_TOKEN: 'x-access-token',
    CONTENT_TYPE: {
      NAME: 'Content-Type',
      JSON: 'application/json',
      FILE: 'multipart/form-data',
      FORM: 'application/x-www-form-urlencoded'
    },
    LANG: 'Accept-Language',
  },

  RESULT_CODE: {
    SUCCESS: 200,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PARAM_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    DUPLICATE: 405,         // 중복오류
    DATABASE_ERR: 406,      // 디비오류
    TOKEN_EXPIRED: 408,     // 토크만료
    TOKEN_INVALID: 409,     // 토큰불일치
    SERVER_ERR: 500,        // 서버오류
  }
}
