
import { makeAutoObservable } from "mobx";

class RootStore {

  user = {
    type: "",
    token: "",
    name: "",
    email: "",
    uid: 0
  };

  constructor() {
    this.loadStore();
    makeAutoObservable(this)
  }

  login(user) {
    this.user = user;
    this.saveStore();
  }

  logout() {
    this.user = {
      type: "",
      token: "",
      name: "",
      email: "",
      uid: 0
    };
    this.saveStore();
  }

  isLogin() {
    return this.user.token !== '';
  }

  getToken() {
    return this.user.token;
  }

  getType() {
    return this.user.type;
  }

  getUid() {
    return this.user.uid;
  }

  getName() {
    return this.user.name;
  }

  loadStore() {
    try {
      let store = JSON.parse(sessionStorage.getItem('eck-store'));
      if (!store) {
        return;
      }

      this.user = store.user;
    } catch (e) {
      console.log(e);
    }
  }

  saveStore() {
    sessionStorage.setItem('eck-store', JSON.stringify(this));
  }
}

export default RootStore;