import React, {useContext, useEffect} from 'react';
import strings from "../../lang/strings";
import {PageSettings} from "../../config/page-settings";
import {checkValidEmail, getSignupType} from "../../helper/util";
import {withRouter} from "react-router-dom";
import {inject} from "mobx-react";
import {Panel, PanelBody, PanelFooter, PanelHeader} from "../../components/panel/panel";

class Register extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            userInfo: {
                name: "",
                email: "",
                password: "",
                passwordCheck: "",
            },
            error: {
                password: false,
                passwordCheck: false,
                name: false,
                email: {
                    empty: false,
                    invalid: false,
                },
            },
        }
    }

    componentDidMount() {
        this.context.handleSetPageHeader(false);
        this.context.handleSetPageSidebar(false);
    }

    handleFormChange(event) {
        const target = event.target;
        const name = target.name;

        const {userInfo} = this.state;
        const {error} = this.state;

        userInfo[name] = target.value;

        if (name == "email") {
            error.email.empty = target.value == "";
            error.email.invalid = target.value != "" && !checkValidEmail(target.value);
        } else {
            error[name] = target.value == "";
        }

        this.setState({
            userInfo: userInfo,
            error: error
        })
    }

    onSaveUser() {
        var self = this;

        const {userInfo} = this.state;
        const {error} = this.state;

        error.name = userInfo.name === ""
        error.email.empty = userInfo.email === ""
        if (!error.email.empty) {
            error.email.invalid = !checkValidEmail(userInfo.email);
        }

        error.password = userInfo.password === ""
        error.passwordCheck = userInfo.passwordCheck == "" || (userInfo.password != userInfo.passwordCheck)

        this.setState({
            error: error
        })

        if (error.name || error.password || (error.email.empty || error.email.invalid) || error.passwordCheck) {
            return;
        }

        this.context.post("/signup", {
            userInfo
        }, function (response) {
            self.context.showToast("success", strings.msg_signup_success_wait_admin_allow)
            setTimeout(function () {
                self.props.history.replace('/login');
            }, 1000);
        }, true);

    }

    onCert() {
        const {userInfo} = this.state;
        const {error} = this.state;
        var self = this;

        error.email.empty = userInfo.email === ""
        if (!error.email.empty) {
            error.email.invalid = !checkValidEmail(userInfo.email);
        }

        this.setState({
            error: error
        })

        if (error.email.empty || error.email.invalid) {
            return;
        }

        this.context.post("/requestCertKey", {
            email: userInfo.email
        }, function (response) {
            self.context.showToast("success", strings.msg_check_mail_box);
        }, true);
    }

    onCancel() {
        this.props.history.replace('/login');
    }

    render() {
        let self = this;
        const {userInfo} = this.state;

        return (
            <div className="col-md-6" style={{margin: "auto"}}>
                <Panel>
                    <PanelHeader noButton={true}>
                        {strings.user_register}
                    </PanelHeader>
                    <PanelBody>
                        <form className="form-horizontal">
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.name}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="name" type="text" required="required"
                                           onChange={(e) => this.handleFormChange(e)}/>
                                    {this.state.error.name &&
                                    <p className="text-danger">{strings.msg_input_name}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.email}</label>
                                <div className="col-sm-9">
                                    <div className="input-group m-b-10">
                                        <input type="email" className="form-control" name="email"
                                               onChange={(e) => this.handleFormChange(e)}/>
                                        <div className="input-group-prepend">
                                            <button className="btn btn-default btn-sm m-l-5" type="button"
                                                    onClick={() => this.onCert()}>{strings.cert_email}</button>
                                        </div>
                                    </div>
                                    {this.state.error.email.empty &&
                                    <p className="text-danger">{strings.msg_input_email}</p>
                                    }
                                    {!this.state.error.email.empty && this.state.error.email.invalid &&
                                    <p className="text-danger">{strings.msg_input_email_invalid}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.password}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="password" name="password" autoComplete={'off'}
                                           onChange={(e) => this.handleFormChange(e)}/>
                                    {this.state.error.password &&
                                    <p className="text-danger">{strings.msg_input_password}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.password_check}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="password" name="passwordCheck"
                                           autoComplete={'off'}
                                           onChange={(e) => this.handleFormChange(e)}/>
                                    {this.state.error.passwordCheck &&
                                    <p className="text-danger">{strings.msg_input_password_check}</p>
                                    }
                                </div>
                            </div>
                        </form>
                    </PanelBody>
                    <PanelFooter className={"text-right"}>
                        <button className="btn btn-white btn-sm" type="button"
                                onClick={() => this.onCancel()}>{strings.cancel}</button>
                        <button className="btn btn-primary btn-sm m-l-5" type="button"
                                onClick={() => this.onSaveUser()}>{strings.save}</button>
                    </PanelFooter>
                </Panel>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(Register));