import React from 'react';
import $ from 'jquery';
import DataTable from "../../components/control/data-table";
import strings from "../../lang/strings";
import {PageSettings} from "../../config/page-settings";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {checkValidEmail, getSignupType} from "../../helper/util";
import * as XLSX from "xlsx";


class User extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            userModal: false,
            userInfo: {
                uid: 0,
                email: "",
                password: "",
                type: "user",
                enterpriseName: "",
                listeningClass: 0,
                loginType: "",
                status: "ok",
                regDate: "",
                lastLogin: "",
            },
            error: {
                password: false,
                name: false,
                email: {
                    empty: false,
                    invalid: false,
                },
                enterpriseName: false,
            },
            start: 0,
        }

        this.refreshTable = this.refreshTable.bind(this);

    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    onUserInfo(user) {
        this.setState({
            userInfo: {
                uid: user.user_uid ?? 0,
                name: user.name ?? "",
                email: user.email ?? "",
                password: "",
                type: user.type,
                enterpriseName: user.enterprise_name ?? "",
                listeningClass: user.title ?? "",
                loginType: user.sign_type ?? "email",
                status: user.status ?? "ok",
                regDate: user.reg_dt ?? "",
                lastLogin: user.last_login_dt ?? "",
            },
            error: {
                password: false,
                name: false,
                email: {
                    empty: false,
                    invalid: false,
                },
                enterpriseName: false,
            },
        })
        this.setState({userModal: !this.state.userModal})
    }

    onDeleteUser(uid) {
        var self = this;
        this.context.showConfirm(strings.msg_confirm_delete, function (response) {
            if (response) {
                self.context.post("/deleteUser", {
                    userUid: uid
                }, function (response) {
                    self.context.showToast("success", strings.msg_operation_success);

                    self.refreshTable();
                }, true);
            }
        })
    }


    onSaveUser() {
        const {userInfo} = this.state;
        const {error} = this.state;
        var self = this;

        error.name = userInfo.name === ""
        error.email.empty = userInfo.email === ""
        if (!error.email.empty) {
            error.email.invalid = !checkValidEmail(userInfo.email);
        }

        if (userInfo.uid == 0) {
            error.password = userInfo.password === "";
        }

        error.enterpriseName = userInfo.type === "enterprise" && userInfo.enterpriseName === "";

        this.setState({
            error: error
        })

        if (error.name || error.password || (error.email.empty || error.email.invalid) || (userInfo.type === "enterprise" && error.enterpriseName)) {
            return;
        }

        this.context.post("/saveUser", {
            userInfo
        }, function (response) {
            self.context.showToast("success", strings.msg_operation_success);

            self.refreshTable();
            self.setState({
                userModal: false
            })
        }, true);

    }

    refreshTable = (refresh) => {
        this.tblUser.draw(refresh);
    };

    handleFormChange(event) {
        const target = event.target;
        const name = target.name;

        const {userInfo} = this.state;
        const {error} = this.state;

        if (name === "type") {
            error.enterpriseName = target.value === "enterprise" && userInfo.enterpriseName === "";
            if (target.value != "enterprise") {
                userInfo.enterpriseName = "";
            }
        }

        userInfo[name] = target.value

        this.setState({
            userInfo: userInfo,
            error: error
        })
    }

    handleUpload(e) {
        e.preventDefault();

        var files = e.target.files, f = files[0];
        var reader = new FileReader();

        var self = this;
        reader.onload = function (e) {
            var data = e.target.result;
            var XLSX = require('xlsx');
            let readData = XLSX.read(data, {type: 'binary'});
            const wsName = readData.SheetNames[0];
            const ws = readData.Sheets[wsName];

            const dataParse = XLSX.utils.sheet_to_json(ws, {header: 1, defval: ""});
            let userList = [];

            let status = true;
            let idx = 1;
            while (idx < dataParse.length) {
                const name = dataParse[idx][0];
                const email = dataParse[idx][1];
                const password = dataParse[idx][2];
                const type = dataParse[idx][3];
                const enterpriseName = dataParse[idx][4];

                if (name == "") {
                    status = false;
                    self.context.showToast("warning", strings.formatString(strings.msg_file_contains_invalid_data, idx + 1, "A"));
                } else if (email == "" || !checkValidEmail(email)) {
                    status = false;
                    self.context.showToast("warning", strings.formatString(strings.msg_file_contains_invalid_data, idx + 1, "B"));
                } else if (password == "") {
                    status = false;
                    self.context.showToast("warning", strings.formatString(strings.msg_file_contains_invalid_data, idx + 1, "C"));
                } else if (["individual", "enterprise", "teacher"].indexOf(type) < 0) {
                    status = false;
                    self.context.showToast("warning", strings.formatString(strings.msg_file_contains_invalid_data, idx + 1, "D"));
                }

                if (type == "enterprise" && enterpriseName == "") {
                    status = false;
                    self.context.showToast("warning", strings.formatString(strings.msg_file_contains_invalid_data, idx + 1, "E"));
                }

                if (!status) {
                    console.log(dataParse[idx]);
                    break;
                }

                let problemItem = {
                    name: name,
                    email: email,
                    password: password,
                    type: type,
                    enterpriseName: enterpriseName,
                }

                userList.push(problemItem);
                idx++;
            }

            if (!status) {
                return;
            }

            self.context.post("/userUpload", {
                userList: userList
            }, function (response) {
                self.context.showToast("success", strings.msg_operation_success);
                $('#fileUser').val("");
                self.refreshTable();
            }, true);
        };

        if (f != null) {
            reader.readAsBinaryString(f)
        }
    }

    onTeacherCheck(userUid) {
        var self = this;

        self.context.post("/checkTeacher", {
            userUid: userUid
        }, function (response) {
            self.context.showToast("success", strings.msg_operation_success);
            self.refreshTable();
        }, true);
    }

    onDownloadSampleExcel() {
        this.context.convertToCsv('user_sample.csv', [
            ['이름', '이메일', '비번', '구분', '기업명'],
            ['enterpriseName', 'enterprise@email.com', 'a', 'enterprise', 'ECk Company'],
            ['individualName', 'individual@email.com', 'a', 'individual', ''],
            ['teacherName', 'teacher@email.com', 'a', 'teacher', ''],
        ])
    }

    render() {
        let self = this;
        let columnNo = 0;

        const {userInfo} = this.state;

        return (
            <div className="row m-t-20">
                <div className="col-md-12 text-right">
                    <button type="button" className="btn btn-info m-r-5 m-b-5"
                            onClick={() => this.onDownloadSampleExcel()}>{strings.sample_download}</button>
                    <input type="file" id="fileUser" accept=".xls,.csv,.xlsx" ref={(fileUpload => {
                        this.fileUpload = fileUpload;
                    })} onChange={(e) => this.handleUpload(e)} hidden/>
                    <button type="button" className="btn btn-success m-r-5 m-b-5"
                            onClick={() => this.fileUpload.click()}>{strings.excel_upload}</button>
                    <button type="button" className="btn btn-primary m-r-5 m-b-5"
                            onClick={() => this.onUserInfo({})}>{strings.register}</button>
                </div>
                <div className="col-md-12" id="tblUserWrapper">
                    <DataTable ref={(ref) => this.tblUser = ref} id="tblUser"
                               customPaginate={true}
                               initialPage={0}
                               hideTotalCount={true}
                               onPageChange={(value) => {
                                   self.state.start = value;
                                   this.refreshTable();
                               }}
                               options={{
                                   columnDefs: [
                                       {targets: [columnNo++], data: 'name', orderable: true,},
                                       {targets: [columnNo++], data: 'email', orderable: true,},
                                       {
                                           targets: [columnNo++],
                                           data: 'type',
                                           orderable: false,
                                           render: function (data, type, full, meta) {
                                               var res = "";
                                               if (data === "teacher") {
                                                   res = strings.teacher;
                                               } else if (data === "enterprise") {
                                                   res = strings.enterprise + ">" + full.enterprise_name
                                               } else {
                                                   res = strings.individual;
                                               }
                                               return res;
                                           }
                                       },
                                       {targets: [columnNo++], data: 'title', orderable: false,},
                                       {
                                           targets: [columnNo++],
                                           data: 'sign_type',
                                           orderable: false,
                                           render: function (data, type, full, meta) {
                                               return getSignupType(data);
                                           }
                                       },
                                       {targets: [columnNo++], data: 'reg_dt', orderable: true,},
                                       {targets: [columnNo++], data: 'last_login_dt', orderable: true,},
                                       {targets: [columnNo++], data: 'user_uid', orderable: false},
                                   ],
                                   order: [[5, 'DESC']],
                                   ajax: {
                                       url: '/userTableList',
                                       data: function (req) {
                                           req.columns = null;
                                           req.onlyUser = "n";
                                           req.requestTeacher = "y";
                                           req.start = self.state.start;
                                           req.length = 10;
                                       }
                                   },
                                   drawCallback: function () {
                                   },
                                   fnCreatedRow: function (row, data, index) {
                                       $('td:eq(7)', row).html("");
                                       $('td:last', row).append(`<button type='button' class='btn btn-info btn-sm m-2' data-id="${index}" onclick="">${strings.edit}</button`);
                                       $('td:last', row).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button`);

                                       $('td:last', row)
                                           .find('button:eq(0)')
                                           .on('click', function () {
                                               self.onUserInfo(data);
                                           });

                                       $('td:last', row)
                                           .find('button:eq(1)')
                                           .on('click', function () {
                                               self.onDeleteUser(data.user_uid);
                                           });

                                       if (data.status == "request") {
                                           $('td:eq(2)', row).html(`
                                               <div style="display: flex; align-items: center;justify-content: space-between">
                                                    <span>${strings.teacher}</span>
                                                    <button type="button" class="btn btn-default">${strings.do_check}</button>
                                               </div>
                                       `);

                                           $('td:eq(2)', row)
                                               .find('button')
                                               .on('click', function () {
                                                   self.onTeacherCheck(data.user_uid);
                                               });

                                       }
                                   }
                               }}>
                        <thead>
                        <tr>
                            <th>{strings.name}</th>
                            <th>{strings.email}</th>
                            <th>{strings.type}</th>
                            <th>{strings.listening_class}</th>
                            <th>{strings.login_type}</th>
                            <th>{strings.reg_date}</th>
                            <th>{strings.last_login}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody/>
                    </DataTable>
                </div>
                <Modal className="modal-lg" isOpen={this.state.userModal}
                       toggle={() => this.setState({userModal: !this.state.userModal})}>
                    <ModalHeader toggle={() => {
                        this.setState({userModal: !this.state.userModal})
                    }}>{strings.user_management}</ModalHeader>
                    <ModalBody>
                        <form className="form-horizontal">
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.name}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="name" type="text" required="required"
                                           onChange={(e) => this.handleFormChange(e)} defaultValue={userInfo.name}/>
                                    {this.state.error.name &&
                                    <p className="text-danger">{strings.msg_input_name}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.email}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="email" name="email" autoComplete={'off'}
                                           onChange={(e) => this.handleFormChange(e)} defaultValue={userInfo.email}/>
                                    {this.state.error.email.empty &&
                                    <p className="text-danger">{strings.msg_input_email}</p>
                                    }
                                    {!this.state.error.email.empty && this.state.error.email.invalid &&
                                    <p className="text-danger">{strings.msg_input_email_invalid}</p>
                                    }
                                </div>
                            </div>
                            {
                                userInfo.loginType == "email" &&
                                <div className="form-group row m-b-15">
                                    <label className="col-sm-3 col-form-label text-right">{strings.password}</label>
                                    <div className="col-sm-9">
                                        <input className="form-control" type="password" name="password"
                                               autoComplete={'off'}
                                               onChange={(e) => this.handleFormChange(e)}
                                               defaultValue={userInfo.password}/>
                                        {this.state.error.password &&
                                        <p className="text-danger">{strings.msg_input_password}</p>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.type}</label>
                                <div className="col-sm-9 text-left" style={{display: 'flex'}}>
                                    <select className="form-control" name="type" id="type" defaultValue={userInfo.type}
                                            onChange={(e) => this.handleFormChange(e)}>
                                        <option value="individual">{strings.individual}</option>
                                        <option value="enterprise">{strings.enterprise}</option>
                                        <option value="teacher">{strings.teacher}</option>
                                    </select>
                                    <span
                                        style={{paddingTop: '7px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <div style={{width: "100%"}}>
                                        <input className="form-control" type="text" name="enterpriseName"
                                               disabled={userInfo.type != "enterprise"}
                                               onChange={(e) => this.handleFormChange(e)}
                                               value={userInfo.enterpriseName}/>
                                        {this.state.error.enterpriseName &&
                                        <p className="text-danger">{strings.msg_input_enterprise_name}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                userInfo.listeningClass !== "" &&
                                <div className="form-group row m-b-15">
                                    <label
                                        className="col-sm-3 col-form-label text-right">{strings.listening_class}</label>
                                    <div className="col-sm-9">
                                        <label className="col-form-label">{this.state.userInfo.listeningClass}</label>
                                    </div>
                                </div>
                            }
                            {
                                userInfo.loginType !== "" &&
                                <div className="form-group row m-b-15">
                                    <label className="col-sm-3 col-form-label text-right">{strings.login_type}</label>
                                    <div className="col-sm-9">
                                        <label className="col-form-label">{getSignupType(userInfo.loginType)}</label>
                                    </div>
                                </div>
                            }
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.status}</label>
                                <div className="col-md-9">
                                    <div className="radio radio-css radio-inline">
                                        <input type="radio" name="status" id="statusEnable"
                                               onChange={(e) => this.handleFormChange(e)}
                                               value="ok" defaultChecked={userInfo.status === "ok"}/>
                                        <label htmlFor="statusEnable">{strings.enable}</label>
                                    </div>
                                    <div className="radio radio-css radio-inline">
                                        <input type="radio" name="status" id="statusDisable"
                                               onChange={(e) => this.handleFormChange(e)}
                                               value="block" defaultChecked={userInfo.status === "block"}/>
                                        <label htmlFor="statusDisable">{strings.disable}</label>
                                    </div>
                                </div>
                            </div>
                            {
                                userInfo.regDate !== "" &&
                                <div className="form-group row m-b-15">
                                    <label className="col-sm-3 col-form-label text-right">{strings.reg_date}</label>
                                    <div className="col-sm-9">
                                        <label className="col-form-label">{userInfo.regDate}</label>
                                    </div>
                                </div>
                            }
                            {
                                userInfo.lastLogin !== "" &&
                                <div className="form-group row m-b-15">
                                    <label className="col-sm-3 col-form-label text-right">{strings.last_login}</label>
                                    <div className="col-sm-9">
                                        <label className="col-form-label">{userInfo.lastLogin}</label>
                                    </div>
                                </div>
                            }

                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white"
                                onClick={() => this.setState({userModal: !this.state.userModal})}>{strings.cancel}</button>
                        <button className="btn btn-primary" onClick={() => this.onSaveUser()}>{strings.save}</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default User;
