import React from 'react';
import $ from 'jquery';
import DataTable from "../../components/control/data-table";
import strings from "../../lang/strings";
import {PageSettings} from "../../config/page-settings";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {checkValidEmail, getSignupType} from "../../helper/util";
import ItemProblem from "../schedule/item-problem";
import {withRouter} from "react-router-dom";
import {inject} from "mobx-react";

class ClassPage extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            classModal: false,
            userModal: false,
            userStart: 0,
            classStart: 0,
            listeningUserCnt: 0,
            classUid: 0,
            classInfo: {
                uid: 0,
                title: "",
                description: "",
                type: "basic",
                teacher: 0,
                category: "",
                categoryUid: 0,
                isDirectInput: false
            },
            error: {
                title: false,
                description: false,
                teacher: false,
                category: {
                    input: false,
                    select: false,
                },
            },
            teacherList: [],
            categoryList: [],
        }
    }

    componentDidMount() {
        this.getTeacherList();
        this.getCategoryList();
    }

    getTeacherList() {
        let self = this;
        self.context.get(
            '/teacherList',
            {},
            function (response) {
                self.setState({
                    teacherList: response.data,
                });
            },
            true
        );
    }

    onChangeMatching(userUid, status) {
        let self = this;
        self.context.post(
            '/changeMatching',
            {
                info: {
                    classUid: self.state.classUid,
                    userUid: userUid,
                    status: status
                }
            },
            function (response) {
                self.refreshUserTable();
                self.refreshClassTable();
            },
            true
        );
    }

    getCategoryList() {
        let self = this;
        self.context.get(
            '/categoryList',
            {},
            function (response) {
                self.setState({
                    categoryList: response.data,
                });
            },
            true
        );
    }

    handleFormChange(event) {
        const target = event.target;
        const name = target.name;

        const {classInfo} = this.state;
        const {error} = this.state;

        classInfo[name] = target.value;

        if (name === "directInput") {
            classInfo.isDirectInput = !classInfo.isDirectInput
            error.category.input = classInfo.isDirectInput && classInfo.category === "";
            error.category.select = !classInfo.isDirectInput && classInfo.categoryUid == 0;
        } else if (name === "teacher") {
            classInfo[name] = target.value;
            error.teacher = target.value == 0;
        } else if (name === "categoryUid") {
            error.category.input = classInfo.isDirectInput && classInfo.category === "";
            error.category.select = !classInfo.isDirectInput && classInfo.categoryUid == 0;
        }

        error.title = classInfo.type == "";
        error.description = classInfo.description == "";
        error.category.input = classInfo.isDirectInput && classInfo.category == "";

        this.setState({
            classInfo: classInfo,
            error: error
        })
    }

    refreshClassTable = (refresh) => {
        // this.state.classStart = 0;
        this.tblClass.draw(refresh);
    };

    refreshUserTable = (refresh) => {
        // this.state.userStart = 0;
        this.tblUser.draw(refresh);
    };


    onUserModal(uid) {
        this.state.classUid = uid;
        this.setState({userModal: !this.state.userModal})
    }

    onClassInfo(info) {
        this.setState({
            classInfo: {
                uid: info.class_uid ?? 0,
                title: info.title ?? "",
                description: info.description ?? "",
                type: info.type == null ? "basic" : info.type,
                teacher: this.props.rootStore.getType() == "teacher" ? this.props.rootStore.getUid() : (info.user_uid ?? 0),
                category: "",
                categoryUid: info.category_uid ?? 0,
                isDirectInput: false
            },
            error: {
                title: false,
                description: false,
                teacher: false,
                category: {
                    input: false,
                    select: false,
                },
            },
            classModal: !this.state.classModal
        })
    }

    onDeleteClass(class_uid) {
        var self = this;
        this.context.showConfirm(strings.msg_confirm_delete_class, function (response) {
            if (response) {
                self.context.post("/deleteClass", {
                    classUid: class_uid
                }, function (response) {
                    self.context.showToast("success", strings.msg_operation_success);

                    self.refreshClassTable();
                }, true);
            }
        })
    }

    onChangeKeyword(event) {
        const target = event.target;
        this.state.keyword = target.value;
        this.state.userStart = 0;

        this.refreshUserTable();
    }

    onSaveClass() {
        const {classInfo} = this.state;
        const {error} = this.state;
        let self = this;

        error.title = classInfo.title === ""
        error.description = classInfo.description === ""
        error.teacher = classInfo.teacher == 0

        error.category.input = classInfo.isDirectInput && classInfo.category === "";
        error.category.select = !classInfo.isDirectInput && classInfo.categoryUid === 0;

        this.setState({
            error: error
        })

        if (error.title || error.description || error.teacher || (error.category.input || error.category.select)) {
            return;
        }

        console.log(classInfo);
        this.context.post("/saveClass", {
            classInfo
        }, function (response) {
            self.context.showToast("success", strings.msg_operation_success);

            self.refreshClassTable();
            self.setState({
                classModal: false
            })

            if (classInfo.isDirectInput && self.props.rootStore.getType() == "admin") {
                self.getCategoryList();
            }
        }, true);
    }

    render() {
        let self = this;
        let columnNo = 0;
        let tblUserColumnNo = 0;

        const {classInfo} = this.state;

        return (
            <div className="row m-t-20">
                <div className="col-md-12 text-right">
                    <button type="button" className="btn btn-primary m-r-5 m-b-5"
                            onClick={() => this.onClassInfo({})}>{strings.class_add}</button>
                </div>
                <div className="col-md-12">
                    <DataTable ref={(ref) => this.tblClass = ref}
                               customPaginate={true}
                               initialPage={0}
                               hideTotalCount={true}
                               onPageChange={(value) => {
                                   self.state.classStart = value;
                                   this.refreshClassTable();
                               }}
                               options={{
                                   columnDefs: [
                                       {targets: [columnNo++], data: 'title', orderable: true,},
                                       {targets: [columnNo++], data: 'name', orderable: true,},
                                       {targets: [columnNo++], data: 'user_cnt', orderable: true,},
                                       {targets: [columnNo++], data: 'reg_dt', orderable: true,},
                                       {
                                           targets: [columnNo++],
                                           data: 'type',
                                           orderable: true,
                                           render: function (data, type, full, meta) {
                                               return data == "basic" ? strings.basic_class : strings.secondary_class;
                                           }
                                       },
                                       {targets: [columnNo++], data: 'category', orderable: true,},
                                       {targets: [columnNo++], data: 'class_uid', orderable: false,},
                                   ],
                                   order: [[3, 'DESC']],
                                   ajax: {
                                       url: '/getClassTableList',
                                       data: function (req) {
                                           req.columns = null;

                                           req.start = self.state.classStart;
                                           req.length = 10;
                                       }
                                   },
                                   drawCallback: function () {
                                   },
                                   fnCreatedRow: function (row, data, dataInd) {
                                       if (data.status == "private") {
                                           $('td:eq(1)', row).html(`
                                               <div style="display: flex; align-items: center;justify-content: space-between">
                                                    <span>${data.title}</span>
                                                    <span class="text-danger">${strings.checking_category}</span>
                                               </div>
                                       `);
                                       }

                                       if (data.type == "secondary") {
                                           $('td:eq(2)', row).html(`
                                               <div style="display: flex; align-items: center;justify-content: space-between">
                                                    <span>${data.user_cnt + strings.cnt}</span>
                                                    <button type="button" class="btn btn-default">+</button>
                                               </div>
                                       `);

                                           $('td:eq(2)', row)
                                               .find('button')
                                               .on('click', function () {
                                                   self.setState({
                                                       listeningUserCnt: data.user_cnt,
                                                   });
                                                   self.onUserModal(data.class_uid);
                                               });

                                       } else {
                                           $('td:eq(2)', row).html(`
                                               <div style="display: flex; align-items: center;justify-content: space-between">
                                                    <span>${data.user_cnt + strings.cnt}</span>
                                                    <span></span>
                                               </div>
                                       `);
                                       }

                                       var editBtn = $(`<button type="button" id="edit" class="btn btn-primary m-r-5">${strings.edit}</button>`).on("click", function () {
                                           self.onClassInfo(data);
                                       });

                                       var deleteBtn = $(`<button type="button" id="delete" class="btn btn-danger">${strings.delete}</button>`).on("click", function () {
                                           self.onDeleteClass(data.class_uid);
                                       });

                                       $('td:last', row).html(editBtn);
                                       $('td:last', row).append(deleteBtn);

                                   }
                               }}>
                        <thead>
                        <tr>
                            <th>{strings.title}</th>
                            <th>{strings.processing_teacher}</th>
                            <th>{strings.users}</th>
                            <th>{strings.create_date}</th>
                            <th>{strings.type}</th>
                            <th>{strings.category}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody/>
                    </DataTable>
                </div>
                <Modal className="modal-lg" isOpen={this.state.classModal}
                       toggle={() => this.setState({classModal: !this.state.classModal})}>
                    <ModalHeader toggle={() => {
                        this.setState({classModal: !this.state.classModal})
                    }}>{strings.class_management}</ModalHeader>
                    <ModalBody>
                        <form className="form-horizontal">
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.title}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" type="text" placeholder={strings.hint_class_title}
                                           name="title"
                                           onChange={(e) => this.handleFormChange(e)} defaultValue={classInfo.title}/>
                                    {this.state.error.title &&
                                    <p className="text-danger">{strings.msg_input_title}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.description}</label>
                                <div className="col-sm-9">
                                    <textarea rows="5" className="form-control" name="description"
                                              onChange={(e) => this.handleFormChange(e)}
                                              defaultValue={classInfo.description}
                                              placeholder={strings.hint_class_description}/>
                                    {this.state.error.description &&
                                    <p className="text-danger">{strings.msg_input_description}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label
                                    className="col-sm-3 col-form-label text-right">{strings.type}</label>
                                <div className="col-sm-9">
                                    <select className="form-control" defaultValue={classInfo.type} name="type"
                                            onChange={(e) => this.handleFormChange(e)}>
                                        <option value="basic">{strings.basic_class}</option>
                                        <option value="secondary">{strings.secondary_class}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label
                                    className="col-sm-3 col-form-label text-right">{strings.teaching_teacher}</label>
                                <div className="col-sm-9">
                                    <select className="form-control" name="teacher" defaultValue={classInfo.teacher}
                                            onChange={(e) => this.handleFormChange(e)}>
                                        {this.props.rootStore.getType() == "admin" &&
                                        <option value={0}>{strings.msg_select_teacher}</option>}
                                        {this.props.rootStore.getType() == "admin" && this.state.teacherList.map(function (teacher, idx) {
                                            return <option key={idx} value={teacher.uid}>{teacher.name}</option>
                                        })}

                                        {this.props.rootStore.getType() == "teacher" && <option
                                            value={this.props.rootStore.getUid()}>{this.props.rootStore.getName()}</option>}
                                    </select>
                                    {this.state.error.teacher &&
                                    <p className="text-danger">{strings.msg_select_teacher}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label
                                    className="col-sm-3 col-form-label text-right">{strings.category}</label>
                                <div className="col-sm-9">
                                    <div className="col-sm-12 m-b-15 text-left"
                                         style={{display: 'flex', paddingLeft: '0px', paddingRight: '0px'}}>
                                        <div className="checkbox checkbox-css checkbox-inline" style={{flex: 'none'}}>
                                            <input type="checkbox" name="directInput" value="" id="inlineCssCheckbox1"
                                                   defaultChecked={classInfo.isDirectInput}
                                                   onChange={(e) => this.handleFormChange(e)}/>
                                            <label htmlFor="inlineCssCheckbox1">{strings.direct_input}</label>
                                        </div>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <div style={{width: "100%"}}>
                                            <input className="form-control" type="text" name="category"
                                                   disabled={!classInfo.isDirectInput}
                                                   onChange={(e) => this.handleFormChange(e)}
                                                   defaultValue={classInfo.category}/>
                                            {this.state.error.category.input &&
                                            <p className="text-danger">{strings.msg_input_category}</p>
                                            }
                                        </div>
                                    </div>
                                    <select className="form-control" name="categoryUid"
                                            disabled={classInfo.isDirectInput}
                                            onChange={(e) => this.handleFormChange(e)}
                                            defaultValue={classInfo.categoryUid}>
                                        <option value={0}>{strings.msg_select_category}</option>
                                        {this.state.categoryList.map(function (category, idx) {
                                            return <option key={idx} value={category.uid}>{category.name}</option>
                                        })}
                                    </select>
                                    {this.state.error.category.select &&
                                    <p className="text-danger">{strings.msg_select_category}</p>
                                    }
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white"
                                onClick={() => this.setState({classModal: !this.state.classModal})}>{strings.cancel}</button>
                        <button className="btn btn-primary" onClick={() => this.onSaveClass()}>{strings.ok}</button>
                    </ModalFooter>
                </Modal>
                <Modal className="modal-lg" isOpen={this.state.userModal}
                       toggle={() => this.setState({userModal: !this.state.userModal})}>
                    <ModalHeader toggle={() => {
                        this.setState({userModal: !this.state.userModal})
                    }}>{strings.listening_user + this.state.listeningUserCnt + strings.cnt}</ModalHeader>
                    <ModalBody>
                        <div className="col-md-12">
                            <div className="input-group input-group-md m-b-10">
                                <div className="input-group-prepend"><span className="input-group-text"><i
                                    className="fa fa-search"></i></span></div>
                                <input type="text" id="keyword" className="form-control" placeholder=""
                                       onChange={(e) => this.onChangeKeyword(e)}/>
                            </div>
                        </div>
                        <div className="col-md-12" id="tblUserModelWrapper">
                            <DataTable ref={(ref) => this.tblUser = ref}
                                       customPaginate={true}
                                       initialPage={0}
                                       hideTotalCount={true}
                                       onPageChange={(value) => {
                                           self.state.userStart = value;
                                           this.refreshUserTable();
                                       }}
                                       options={{
                                           columnDefs: [
                                               {targets: [tblUserColumnNo++], data: 'name', orderable: true,},
                                               {targets: [tblUserColumnNo++], data: 'email', orderable: true,},
                                               {
                                                   targets: [tblUserColumnNo++],
                                                   data: 'type',
                                                   orderable: false,
                                                   render: function (data, type, full, meta) {
                                                       var res = "";
                                                       if (data === "teacher") {
                                                           res = strings.teacher;
                                                       } else if (data === "enterprise") {
                                                           res = strings.enterprise + ">" + full.enterprise_name
                                                       } else {
                                                           res = strings.individual;
                                                       }
                                                       return res;
                                                   }
                                               },
                                               {targets: [tblUserColumnNo++], data: 'title', orderable: false,},
                                               {
                                                   targets: [tblUserColumnNo++],
                                                   data: 'sign_type',
                                                   orderable: false,
                                                   render: function (data, type, full, meta) {
                                                       return getSignupType(data);
                                                   }
                                               },
                                               {targets: [tblUserColumnNo++], data: 'reg_dt', orderable: true,},
                                               {targets: [tblUserColumnNo++], data: 'last_login_dt', orderable: true,},
                                               {
                                                   targets: [tblUserColumnNo++],
                                                   data: 'user_uid',
                                                   orderable: false,
                                               },
                                           ],
                                           order: [[5, 'DESC']],
                                           ajax: {
                                               url: '/userTableList',
                                               data: function (req) {
                                                   req.columns = null;
                                                   req.start = self.state.userStart;
                                                   req.keyword = self.state.keyword;
                                                   req.onlyUser = "y";
                                                   req.requestTeacher = "n";
                                                   req.length = 10;
                                               }
                                           },
                                           drawCallback: function () {
                                           },
                                           fnCreatedRow: function (row, data, dataInd) {
                                               $('td:last', row).html("");
                                               var btn = $('<button type="button" class="btn"></button>');

                                               if (data.title == "-") {
                                                   btn.text(`${strings.matching_request}`).on("click", function () {
                                                       self.onChangeMatching(data.user_uid, 'y');
                                                   }).addClass("btn-primary");
                                               } else {
                                                   var user_uid = `${self.props.rootStore.getUid()}`;
                                                   var user_type = `${self.props.rootStore.getType()}`;

                                                   if(user_type == "admin") {
                                                       btn.text(`${strings.matching_cancel}`).on("click", function () {
                                                           self.onChangeMatching(data.user_uid, 'n');
                                                       }).addClass("btn-danger");
                                                   } else {
                                                       if (data.class_user_uid == user_uid) {
                                                           btn.text(`${strings.matching_cancel}`).on("click", function () {
                                                               self.onChangeMatching(data.user_uid, 'n');
                                                           }).addClass("btn-danger");
                                                       } else {
                                                           btn.text(`${strings.matching_complete}`).addClass("btn-default");
                                                       }
                                                   }
                                               }

                                               $('td:last', row).append(btn);
                                           }
                                       }}>
                                <thead>
                                <tr>
                                    <th>{strings.name}</th>
                                    <th>{strings.email}</th>
                                    <th>{strings.type}</th>
                                    <th>{strings.listening_class}</th>
                                    <th>{strings.login_type}</th>
                                    <th>{strings.reg_date}</th>
                                    <th>{strings.last_login}</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody/>
                            </DataTable>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-primary"
                                onClick={() => this.setState({userModal: !this.state.userModal})}>{strings.ok}</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(ClassPage));
