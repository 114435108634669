import React from 'react';
import strings from "../../lang/strings";

class ItemProblem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: this.props.error,
        }
    }

    onChangeItem(e) {
        this.props.onItemChange(e);

        let {error} = this.state;

        if (e.target.name == "question" || e.target.name == "answer") {
            error[e.target.name] = e.target.value == "";
        } else {
            error.keyword.empty = e.target.value == "";
            error.keyword.invalid = e.target.value != "" && e.target.value.split(",").length > 4;
        }

        this.setState({
            error: error
        });
    }

    onDeleteItem() {
        this.props.onItemDelete();
    }

    render() {
        return (
            <form className="form-horizontal question-row">
                <div className="form-group row" style={{borderBottom: "1px solid #000000", paddingBottom: "13px"}}>
                    <div className="col-md-2" style={{"display": "flex", "alignItems": "center"}}>
                        {strings.question}
                    </div>
                    <div className="col-md-10">
                        <input type="text" name="question" value={this.props.question}
                               onChange={(e) => this.onChangeItem(e)} className="problem-input form-control m-b-5"/>
                        {this.state.error.question &&
                        <p className="text-danger">{strings.msg_input_question}</p>
                        }
                    </div>
                </div>
                <div className="form-group row" style={{borderBottom: "1px solid #000000", paddingBottom: "13px"}}>
                    <div className="col-md-2" style={{"display": "flex", "alignItems": "center"}}>{strings.answer}:
                    </div>
                    <div className="col-md-10">
                        <input type="text" name="answer" value={this.props.answer}
                               onChange={(e) => this.onChangeItem(e)} className="form-control problem-input"/>
                        {this.state.error.answer &&
                        <p className="text-danger">{strings.msg_input_answer}</p>
                        }
                    </div>
                </div>
                <div className="form-group row" style={{borderBottom: "1px solid #000000", paddingBottom: "13px"}}>
                    <div className="col-md-2" style={{"display": "flex", "alignItems": "center"}}>{strings.keyword}:
                    </div>
                    <div className="col-md-10">
                        <input type="text" name="keyword" value={this.props.keyword}
                               onChange={(e) => this.onChangeItem(e)} className="form-control problem-input"/>
                        {this.state.error.keyword.empty &&
                        <p className="text-danger">{strings.msg_input_keyword}</p>
                        }
                        {this.state.error.keyword.invalid &&
                        <p className="text-danger">{strings.msg_over_keyword_count}</p>
                        }
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-12">
                        <button className="btn btn-default float-end" type="button" onClick={()=> this.onDeleteItem()}>{strings.delete}</button>
                    </div>
                </div>
            </form>
        );
    }

}

export default ItemProblem;