export default {
    modal: {
        alert: '알림^^',
        confirm: '확인',
        ok: '확인',
        cancel: '취소'
    },

    user_management:'회원관리',
    teacher_management:'강사관리',
    virtual_teacher_management:'가상강사관리',
    class_management:'클래스관리',
    class_schedule_management:'클래스 차시관리',
    category_management:'카테고리관리',
    server_error:'서버 오류 발생',

    project_name: 'ECK',
    notify: '알림',
    admin: '관리자',
    id: 'ID',
    password: '비번',
    change_password: '비밀번호변경',
    logout: '로그아웃',
    login: '로그인',
    dashboard: '대시보드',
    name: '이름',
    email: '메일',
    type: '분류',
    listening_class: '수강 클래스',
    login_type: '로그인플랫폼',
    reg_date: '가입일',
    last_login: '최근로그인',
    register: '등록',
    delete: '삭제',
    edit: '편집',
    cancel: '취소',
    save: '저장',
    teacher: '강사',
    enterprise: '기업',
    individual: '개인',
    status: '상태',
    enable: '활성화',
    disable: '비활성화',
    excel_upload: '엑셀파일 업로드',
    teaching_class: '진행클래스',
    teacher_delete: '강사 삭제',
    teacher_add: '강사 추가',
    ok: '확인',
    class_add: '클래스추가',
    title: '제목',
    processing_teacher: '진행강사',
    users: '수강생',
    create_date: '생성일',
    category: '카테고리',
    description: '설명',
    basic_class: '정규클래스',
    secondary_class: '수업연계클래스',
    hint_class_title: '클래스 제목을 입력해주세요',
    hint_class_description: '클래스에 관한 설명을 입력해주세요',
    teaching_teacher: '지정강사',
    direct_input: '직접입력',
    schedule_add: '차시추가',
    class: '클래스',
    class_schedule: '클래스 차시',
    schedule: '차시',
    quiz_auto_generation: '퀴즈 자동생성',
    category_delete: '카테고리 삭제',
    category_add: '카테고리 생성',
    category_name: '카테고리 이름',
    creator: '생성자',
    checking: '검수',
    using_class: '적용된 강의수',
    hint_category: '내용을 입력해주세요',
    add: '추가',
    question: 'Q',
    answer: 'A',
    keyword: 'Keyword',
    cert_email: '이메일인증',
    user_register: '회원가입',
    msg_input_name: '이름을 입력해주세요.',
    msg_input_email: '메일을 입력해주세요.',
    msg_input_email_invalid: '메일형식이 유효하지 않습니다.',
    msg_input_password: '비번을 입력해주세요.',
    msg_input_password_check: '비번을 재입력해주세요.',
    msg_input_enterprise_name: '기업명을 입력해주세요.',
    msg_input_title: '제목을 입력해주세요.',
    msg_input_description: '설명을 입력해주세요.',
    msg_input_category: '카테고리를 입력해주세요.',
    msg_select_category: '카테고리를 선택해주세요.',
    msg_select_teacher: '강사를 선택해주세요.',
    kakao_signup: '네이버로그인',
    google_signup: '구글로그인',
    apple_signup: '애플로그인',
    email_signup: '이메일로그인',
    msg_operation_success: '성공!',
    msg_operation_fail: '실패!',
    msg_confirm_delete: '삭제하시겠습니까?',
    msg_select_user: '유저를 선택해주세요.',
    cnt: '명',
    request: '요청',
    do_check: '승인하기',
    listening_user: '수강생 ',
    matching_request: '매칭요청',
    matching_cancel: '매칭취소',
    matching_complete: '매칭완료',
    register_class_first: '먼저 클래스를 등록해주세요.',
    msg_input_problem: '문제를 입력해주세요.',
    msg_confirm_delete_class: '클래스를 삭제하시겠습니까?\n삭제하는 경우 연관차시 및 학습이력들이 모두 삭제됩니다!',
    msg_confirm_withdraw: '탈퇴하시겠습니까?\n탈퇴시 모든 데이터가 삭제되며 복구할 수 없습니다.',
    msg_confirm_delete_schedule: '차시를 삭제하시겠습니까?\n삭제하는 경우 해당 차시 학습이력들이 모두 삭제됩니다!',
    msg_confirm_delete_category: '이 카테고리를 삭제하시겠습니까?\n삭제하는 경우 연관된 클래스들이 모두 삭제됩니다!',
    voice: 'Voice',
    face: '얼굴',
    gender: '성별',
    age: '나이',
    accent: '억양',
    bg_url: '미리보기 URL',
    video_url: '대기모션 URL',
    bg_img_url: '배경이미지 URL',
    profile: '프로필',
    msg_input_voice: 'voice id를 입력해주세요.',
    msg_input_face: 'face id를 입력해주세요.',
    msg_input_accent: '억양을 입력해주세요.',
    msg_input_bg_url: '미리보기 URL을 입력해주세요.',
    msg_input_video_url: '대기모션 URL을 입력해주세요.',
    msg_input_bg_img_url: '배경이미지 URL을 입력해주세요.',
    msg_upload_profile: '프로필을 선택해주세요.',
    profile_upload: '프로필업로드',
    male: '남성',
    female: '여성',
    register_as_teacher: '강사로 회원가입',
    password_check: '비번 검사',
    msg_signup_success_wait_admin_allow: "회원가입이 완료되었습니다.\n관리자가 승인한 이후에 로그인이 가능합니다.",
    msg_check_mail_box: "메일을 확인해주세요.",
    msg_input_current_password: "현재 비번을 입력해주세요.",
    msg_input_new_password: "새 비번을 입력해주세요.",
    msg_confirm_password: "비번을 재입력해주세요.",
    current_password: "현재 비번",
    new_password: "새 비번",
    new_password_confirm: "새 비번 확인",
    msg_complete_problem: "질문을 입력해주세요.",
    add_problem: "질문추가",
    checking_category: "관리자검수중",
    msg_check_problem: "질문내용을 확인해주세요.",
    msg_over_keyword_count: "최대 4개까지의 키워드를 등록가능합니다.",
    msg_input_question: "질문을 입력해주세요.",
    msg_input_answer: "답변을 입력해주세요.",
    msg_input_keyword: "키워드를 입력해주세요.",
    msg_file_contains_invalid_data: "엑셀파일의 {1}{0}셀에 잘못된 데이터가 들어있습니다.",
    sample_download: "샘플엑셀 다운로드",
    delete_all: "전체삭제",
    withdraw: "회원탈퇴",
    msg_impossible_delete_super_admin: "관리자는 탈퇴할 수 없습니다.^^",
    A: "A"
}
