import React from 'react';
import {PageSettings} from './config/page-settings.js';

import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import Content from './components/content/content.jsx';
import LoadingBar from "./components/control/loading-bar";
import CommonModal from "./components/control/modal";
import {withRouter} from "react-router-dom";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {inject, observer} from "mobx-react";
import BaseApp from "./components/base-app";

class App extends BaseApp {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="app-container">
                <ReactNotification/>
                <PageSettings.Provider value={this.state}>
                    <div className={
                        'fade page-sidebar-fixed show page-container page-header-fixed ' +
                        (this.state.pageSidebar ? '' : 'page-without-sidebar ') +
                        (this.state.pageSidebarToggled ? 'page-sidebar-toggled ' : '') +
                        (this.state.hasScroll ? 'has-scroll ' : '')
                    }>
                        {this.state.pageHeader && <Header/>}
                        {this.state.pageSidebar && <Sidebar/>}
                        <Content/>
                        <CommonModal {...this.state.modal} onModalClose={this.onModalClose}/>
                        {this.state.pageLoading && <LoadingBar/>}
                    </div>
                </PageSettings.Provider>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(observer(App)));
