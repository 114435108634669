import React from 'react';
import { Redirect } from 'react-router';

import Login from "../pages/auth/login";
import User from "../pages/user/user";
import strings from "../lang/strings";
import Teacher from "../pages/teacher/teacher";
import ClassPage from "../pages/class/class";
import Schedule from "../pages/schedule/schedule";
import Category from "../pages/category/category";
import VirtualTeacher from "../pages/virtual_teacher/virtual_teacher";
import Register from "../pages/auth/register";

const routes = [
  {
    path: '/',
    exact: true,
    private: true,
    component: () => <Redirect to='/user'/>
  },
  {
    path: '/register',
    exact: true,
    private: false,
    title: strings.user_register,
    component: () => <Register/>
  },
  {
    path: '/login',
    exact: true,
    private: false,
    title: strings.login,
    component: () => <Login/>
  },
  {
    path: '/user',
    exact: true,
    private: true,
    component: () => <User/>
  },
  {
    path: '/teacher',
    exact: true,
    private: true,
    component: () => <Teacher/>
  },
  {
    path: '/virtualTeacher',
    exact: true,
    private: true,
    component: () => <VirtualTeacher/>
  },
  {
    path: '/class',
    exact: true,
    private: true,
    component: () => <ClassPage/>
  },
  {
    path: '/schedule',
    exact: true,
    private: true,
    component: () => <Schedule/>
  },
  {
    path: '/category',
    exact: true,
    private: true,
    component: () => <Category/>
  },
];


export default routes;
