import React from 'react';
import {Route, withRouter} from 'react-router-dom';
import SidebarNavList from './sidebar-nav-list.jsx';
import {AdminMenu, TeacherMenu} from './menu.jsx';
import {inject} from "mobx-react";

class SidebarNav extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1,
      adminMenus: AdminMenu,
      teacherMenus: TeacherMenu,
    };
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    if (this.state.clicked === -1 && match) {
      this.setState({
        active: -1,
        clicked: 1
      });
    } else {
      this.setState(state => ({
        active: (state.active === i ? -1 : i),
        clicked: 1
      }));
    }
  }

  render() {
      var self = this;
    return (
      <ul className="nav">
        <li className="nav-header"></li>
        {self.props.rootStore.getType() == "admin" && this.state.adminMenus.map((menu, i) => (
          <Route path={menu.path} exact={menu.exact} key={i} children={({match}) => (
            <SidebarNavList
              data={menu}
              key={i}
              expand={(e) => this.handleExpand(e, i, match)}
              active={i === this.state.active}
              clicked={this.state.clicked}
            />
          )}/>
        ))}
        {self.props.rootStore.getType() == "teacher" && this.state.teacherMenus.map((menu, i) => (
            <Route path={menu.path} exact={menu.exact} key={i} children={({match}) => (
                <SidebarNavList
                    data={menu}
                    key={i}
                    expand={(e) => this.handleExpand(e, i, match)}
                    active={i === this.state.active}
                    clicked={this.state.clicked}
                />
            )}/>
        ))}
      </ul>
    );
  }
}

export default withRouter(inject('rootStore')(SidebarNav));
// export default inject('rootStore')(SidebarNav);
