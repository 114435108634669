import ko from "./locales/ko";
// import en from "./locales/en";
import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
  ko: ko,
  // en: en
});
strings.setLanguage('ko');

export default strings;
