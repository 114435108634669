import React, { useContext, useEffect, useRef, useState } from "react";
import { PageSettings } from "../../config/page-settings";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import strings from "../../lang/strings";
import axios from "axios";

const Login = ({rootStore, history}) => {
  const context = useContext(PageSettings);
  const idRef = useRef();

  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');

  useEffect(() => {
    context.handleSetPageHeader(false);
    context.handleSetPageSidebar(false);

    idRef?.current?.focus();

    return () => {
      context.handleSetPageHeader(true);
      context.handleSetPageSidebar(true);
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    context.post(
        '/login',
        {
          email: email,
          password: pwd
        },
        function (response) {
          rootStore.login(response.data);

          if(response.data.type == "admin") {
            history.push('/user');
          } else {
            history.push('/class');
          }
        },
        true
    );
  }

  return (
    <React.Fragment>
      <div className="login-cover">
        <div className="login-cover-image"
             style={{backgroundImage: 'url(/assets/img/login-bg.jpg)'}}/>
        <div className="login-cover-bg"/>
      </div>

      <div className="login login-v2">
        <div className="login-header">
          <div className="brand">
            <span className="logo"/> {strings.admin}
          </div>
          <div className="icon hide">
            <i className="fa fa-lock"/>
          </div>
        </div>
        <div className="login-content">
          <form className="margin-bottom-0" onSubmit={handleSubmit}>
            <div className="form-group m-b-20">
              <input type="email"
                     ref={idRef}
                     autoComplete={'off'}
                     className="form-control form-control-lg"
                     placeholder={strings.email}
                     value={email}
                     onChange={e => setEmail(e.target.value)}
                     required
              />
            </div>
            <div className="form-group m-b-20">
              <input type="password"
                     className="form-control form-control-lg"
                     placeholder={strings.password}
                     value={pwd}
                     onChange={e => setPwd(e.target.value)}
                     required/>
            </div>
            <div className="login-buttons">
              <button type="submit" className="btn btn-success btn-block btn-lg">{strings.login}</button>
            </div>
            <div className="m-t-20">
              <a href="/register">{strings.register_as_teacher}</a>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(inject('rootStore')(Login));
