import React from 'react';
import $ from 'jquery';
import DataTable from "../../components/control/data-table";
import strings from "../../lang/strings";
import {PageSettings} from "../../config/page-settings";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import ItemProblem from "./item-problem";
import _ from "underscore";

class Schedule extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            scheduleModal: false,
            start: 0,
            classUid: 0,
            autoGenerateYn: "n",
            scheduleIndex: 0,

            problemList: [],
            classList: [],
            scheduleIdxList: [],
        }

    }

    refreshTable = (refresh) => {
        this.tblSchedule.draw(refresh);
    };

    componentDidMount() {
        this.getClassList();
    }

    handleFormChange(event) {
        const target = event.target;
        const name = target.name;

        var self = this;

        if (name === "class") {
            let idx = $("#class > option[value='" + target.value + "']").attr("data-id");
            if (idx < 0) {
                self.setState({
                    scheduleIndex: -1,
                    scheduleIdxList: []
                });
                return;
            }

            self.setState({
                classUid: target.value,
                scheduleIdxList: self.state.classList[idx].scheduleIdxList,
                scheduleIndex: self.state.classList[idx].scheduleIdxList[self.state.classList[idx].scheduleIdxList.length - 1]
            })
            self.onScheduleInfo(target.value, self.state.classList[idx].scheduleIdxList[self.state.classList[idx].scheduleIdxList.length - 1]);
        } else if (name == "idx") {
            self.setState({
                scheduleIndex: target.value
            });
            self.onScheduleInfo(self.state.classUid, target.value);
        } else {
            self.setState({
                autoGenerateYn: self.state.autoGenerateYn == "n" ? "y" : "n"
            })
        }
    }

    getClassList() {
        let self = this;
        self.context.get(
            '/classList',
            {},
            function (response) {
                self.setState({
                    classList: response.data,
                });
            },
            true
        );
    }

    onScheduleModal(schedule) {
        var self = this;

        if (self.state.classList.length == 0) {
            self.context.showToast("warning", strings.register_class_first);
            return;
        }

        self.setState({
            problemList: []
        });

        if (schedule == null) {
            self.setState({
                /*scheduleIndex: self.state.classList[0].scheduleIdxList[self.state.classList[0].scheduleIdxList.length - 1],
                scheduleIdxList: self.state.classList[0].scheduleIdxList,
                classUid: self.state.classList[0].class_uid*/
                scheduleIndex: -1,
                scheduleIdxList: [],
                classUid: -1
            })
        } else {

            let scheduleIdxList = [];
            this.state.classList.map(function (item, idx) {
                if (item.class_uid == schedule.class_uid) {
                    scheduleIdxList = item.scheduleIdxList
                }
            });

            self.setState({
                scheduleIndex: schedule.idx,
                classUid: schedule.class_uid,
                scheduleIdxList: scheduleIdxList,
                autoGenerateYn: schedule.auto_generate_yn
            })
        }

        self.context.get(
            '/scheduleInfo',
            {
                classUid: this.state.classUid,
                scheduleIdx: schedule == null ? self.state.classList[0].scheduleIdxList[self.state.classList[0].scheduleIdxList.length - 1] : schedule.idx
            },
            function (response) {
                let problemList = [];

                response.data.problemList.forEach(function (problem) {
                    let problemItem = {
                        uid: problem.uid,
                        question: problem.question,
                        answer: problem.answer,
                        keyword: problem.keyword,
                        error: {
                            question: false,
                            answer: false,
                            keyword: {
                                empty: false,
                                invalid: false,
                            }
                        }
                    }

                    problemList.push(problemItem);
                });

                self.setState({
                    autoGenerateYn: response.data.auto_generate_yn,
                    problemList: problemList
                });

                self.setState({scheduleModal: !self.state.scheduleModal});
            },
            true
        );

    }

    onScheduleInfo(classUid, scheduleIdx) {
        let self = this;
        self.context.get(
            '/scheduleInfo',
            {
                classUid: classUid,
                scheduleIdx: scheduleIdx
            },
            function (response) {
                let problemList = [];

                response.data.problemList.forEach(function (problem) {
                    let problemItem = {
                        uid: problem.uid,
                        question: problem.question,
                        answer: problem.answer,
                        keyword: problem.keyword,
                        error: {
                            question: false,
                            answer: false,
                            keyword: {
                                empty: false,
                                invalid: false,
                            }
                        }
                    }

                    problemList.push(problemItem);
                });

                self.setState({
                    autoGenerateYn: response.data.auto_generate_yn,
                    problemList: problemList
                });
            },
            true
        );
    }

    onSaveSchedule() {
        let self = this;
        let status = true;

        const {problemList} = this.state;

        if (self.state.classUid < 0) {
            self.context.showToast("warning", '클래스를 선택해주세요.');
            return;
        }

        if (self.state.scheduleIndex < 0) {
            self.context.showToast("warning", '차시를 선택해주세요.');
            return;
        }

        if (problemList.length == 0) {
            self.context.showToast("warning", strings.msg_input_problem);
            return;
        }

        let idx = 0;
        while (idx < problemList.length) {
            let problem = problemList[idx];

            problemList[idx].error.question = problem.question == "";
            problemList[idx].error.answer = problem.answer == "";
            problemList[idx].error.keyword.empty = problem.keyword == "";
            problemList[idx].error.keyword.invalid = problem.keyword != "" && problem.keyword.toString().split(",").length > 4;

            if (status == true) {
                status = !(problemList[idx].error.question || problemList[idx].error.answer || problemList[idx].error.keyword.empty || problemList[idx].error.invalid);
            }
            idx++;
        }

        if (status == false) {
            self.setState({
                problemList: problemList
            });
            self.context.showToast("warning", strings.msg_check_problem);
            return;
        }

        self.context.post("/saveSchedule", {
            schedule: {
                classUid: self.state.classUid,
                scheduleIdx: self.state.scheduleIndex,
                autoGenerateYn: self.state.autoGenerateYn,
                problemList: self.state.problemList
            }
        }, function (response) {
            self.context.showToast("success", strings.msg_operation_success);

            self.refreshTable();
            self.setState({
                scheduleModal: false
            })

            self.getClassList();
        }, true);
    }

    handleUpload(e) {
        e.preventDefault();
        var self = this;

        const {problemList} = this.state;

        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            var XLSX = require('xlsx');
            let readData = XLSX.read(data, {type: 'binary'});
            const wsName = readData.SheetNames[0];
            const ws = readData.Sheets[wsName];

            const dataParse = XLSX.utils.sheet_to_json(ws, {header: 1, defval: ""});

            for (var i = 1; i < dataParse.length; i++) {
                let problemItem = {
                    uid: 0,
                    question: dataParse[i][0].trim(),
                    answer: dataParse[i][1].trim(),
                    keyword: dataParse[i][2].trim(),
                    error: {
                        question: false,
                        answer: false,
                        keyword: {
                            empty: false,
                            invalid: false,
                        }
                    }
                }

                problemList.push(problemItem);
            }

            self.setState({
                problemList: problemList
            });
        };

        if (f != null) {
            reader.readAsBinaryString(f)
        }
    }

    onAddProblem() {
        const {problemList} = this.state;

        // const lastProblem = problemList[problemList.length - 1];
        //
        // if (lastProblem != undefined && (lastProblem.question == "" || lastProblem.answer == "" || lastProblem.keyword == "")) {
        //     this.context.showToast("warning", strings.msg_complete_problem);
        //     return;
        // }

        problemList.push({
            uid: 0,
            question: "",
            answer: "",
            keyword: "",
            error: {
                question: false,
                answer: false,
                keyword: {
                    empty: false,
                    invalid: false,
                }
            }
        });

        this.setState({
            problemList: problemList
        });
    }

    onDeleteAll () {
        this.setState({
            problemList: []
        });
    }
    onItemChange(e, i) {
        const {problemList} = this.state;

        problemList[i][e.target.name] = e.target.value;

        this.setState({
            problemList: problemList
        });
    }

    onItemDelete(i) {
        const {problemList} = this.state;

        let newList = _.without(problemList, problemList[i]);

        this.setState({
            problemList: newList
        });
    }

    onDownloadSampleExcel() {
        this.context.convertToCsv('schedule_sample.csv', [
            ['질문', '답변', '키워드'],
            ['So, michle where are you from?', "I'm from London.", 'from, London'],
            ['What does this word mean?', "This means peace.", 'mean, peace'],
        ])
    }

    onDeleteSchedule(schedule_uid) {
        var self = this;
        this.context.showConfirm(strings.msg_confirm_delete_schedule, function (response) {
            if (response) {
                self.context.post("/deleteSchedule", {
                    scheduleUid: schedule_uid
                }, function (response) {
                    self.context.showToast("success", strings.msg_operation_success);

                    self.refreshTable();
                }, true);
            }
        })
    }

    render() {
        let self = this;
        let columnNo = 0;

        return (
            <div className="row m-t-20">
                <div className="col-md-12 text-right">
                    <button type="button" className="btn btn-primary m-r-5 m-b-5"
                            onClick={() => this.onScheduleModal(null)}>{strings.schedule_add}</button>
                </div>
                <div className="col-md-12">
                    <DataTable ref={(ref) => this.tblSchedule = ref}
                               customPaginate={true}
                               initialPage={0}
                               hideTotalCount={true}
                               onPageChange={(value) => {
                                   self.state.start = value;
                                   this.refreshTable();
                               }}
                               options={{
                                   columnDefs: [
                                       {targets: [columnNo++], data: 'title', orderable: true,},
                                       {
                                           targets: [columnNo++],
                                           data: 'idx',
                                           orderable: true,
                                           render(data, type, full, meta) {
                                               return data + strings.schedule;
                                           }
                                       },
                                       {targets: [columnNo++], data: 'reg_dt', orderable: true,},
                                       {
                                           targets: [columnNo++],
                                           data: 'type',
                                           orderable: true,
                                           render: function (data, type, full, meta) {
                                               return data == "basic" ? strings.basic_class : strings.secondary_class;
                                           }
                                       },
                                       {targets: [columnNo++], data: 'category', orderable: true,},
                                       {targets: [columnNo++], data: 'schedule_uid', orderable: false,},
                                   ],
                                   order: [[2, 'DESC']],
                                   ajax: {
                                       url: '/getScheduleTableList',
                                       data: function (req) {
                                           req.columns = null;
                                           req.start = self.state.start;
                                           req.length = 10;
                                       }
                                   },
                                   drawCallback: function () {
                                   },
                                   fnCreatedRow: function (row, data, dataInd) {

                                       var editBtn = $(`<button type="button" id="edit" class="btn btn-primary m-r-5">${strings.edit}</button>`).on("click", function () {
                                           self.onScheduleModal(data);
                                       });

                                       var deleteBtn = $(`<button type="button" id="delete" class="btn btn-danger">${strings.delete}</button>`).on("click", function () {
                                           self.onDeleteSchedule(data.schedule_uid);
                                       });

                                       $('td:last', row).html(editBtn);
                                       $('td:last', row).append(deleteBtn);
                                   }
                               }}>
                        <thead>
                        <tr>
                            <th>{strings.class}</th>
                            <th>{strings.schedule}</th>
                            <th>{strings.reg_date}</th>
                            <th>{strings.type}</th>
                            <th>{strings.category}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody/>
                    </DataTable>
                </div>
                <Modal className="modal-lg" isOpen={this.state.scheduleModal}
                       toggle={() => this.setState({scheduleModal: !this.state.scheduleModal})}>
                    <ModalHeader toggle={() => {
                        this.setState({scheduleModal: !this.state.scheduleModal})
                    }}>{strings.class_schedule_management}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-4">
                                <form>
                                    <div className="form-group">
                                        <label>{strings.class}</label>
                                        <select className="form-control" id="class" name="class"
                                                defaultValue={this.state.classUid}
                                                onChange={(e) => this.handleFormChange(e)}>
                                            <option key="-1" data-id="-1" value="-1">선택하세요</option>
                                            {this.state.classList.map(function (item, idx) {
                                                return <option key={idx} data-id={idx}
                                                               value={item.class_uid}>{item.title}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>{strings.class_schedule}</label>
                                        <select className="form-control" name="idx"
                                                value={this.state.scheduleIndex}
                                                onChange={(e) => this.handleFormChange(e)}>
                                            <option key="-1" value="-1">선택하세요</option>
                                            {this.state.scheduleIdxList.map(function (item, idx) {
                                                return <option key={idx}
                                                               value={item}>{item + strings.schedule}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <div className="checkbox checkbox-css checkbox-inline" style={{flex: 'none'}}>
                                            <input type="checkbox" value="" id="inlineCssCheckbox1" name="autoGenerate"
                                                   checked={this.state.autoGenerateYn == "y"}
                                                   onChange={(e) => this.handleFormChange(e)}/>
                                            <label htmlFor="inlineCssCheckbox1">{strings.quiz_auto_generation}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 m-t-40">
                                        <button type="button" className="btn btn-info m-r-5 m-b-5"
                                                onClick={() => this.onDownloadSampleExcel()}>{strings.sample_download}</button>
                                        <button type="button" className="btn btn-success m-r-5 m-b-5"
                                                onClick={() => this.fileUpload.click()}>{strings.excel_upload}</button>
                                        <input type="file" accept=".xls,.csv,.xlsx" ref={(fileUpload => {
                                            this.fileUpload = fileUpload;
                                        })} onChange={(e) => this.handleUpload(e)} hidden/>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-8" style={{maxHeight: "500px", overflowY: "scroll"}}>
                                {this.state.problemList.map(function (problem, idx) {
                                    problem.idx = idx;
                                    problem.onItemChange = (e) => {
                                        self.onItemChange(e, idx);
                                    }

                                    problem.onItemDelete = () => {
                                        self.onItemDelete(idx);
                                    }

                                    return <ItemProblem key={idx}
                                                        {...problem}
                                    />
                                })}
                                <button type="button" className="btn btn-success m-r-5 m-b-5"
                                        onClick={() => this.onAddProblem()}>{strings.add_problem}</button>
                                <button type="button" className="btn btn-danger float-end"
                                        onClick={() => this.onDeleteAll()}>{strings.delete_all}</button>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white"
                                onClick={() => this.setState({scheduleModal: !this.state.scheduleModal})}>{strings.cancel}</button>
                        <button className="btn btn-primary"
                                onClick={() => this.onSaveSchedule()}>{strings.save}</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default Schedule;
