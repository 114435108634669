import React from 'react';
import $ from 'jquery';
import DataTable from "../../components/control/data-table";
import strings from "../../lang/strings";
import {PageSettings} from "../../config/page-settings";
import {Modal, ModalHeader, ModalBody, ModalFooter, Form} from 'reactstrap';
import {checkValidEmail, getSignupType} from "../../helper/util";
import profile from "../../components/header/dropdown/profile";


class VirtualTeacher extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.state = {
            userModal: false,
            userInfo: {
                uid: 0,
                name: "",
                voice: "",
                face: "user",
                gender: "",
                age: 10,
                accent: "",
                bg_url: "",
                video_url: "",
                bg_img_url: "",
                profile: "",
            },
            error: {
                name: false,
                voice: false,
                face: false,
                gender: false,
                age: false,
                accent: false,
                bg_url: false,
                video_url: false,
                bg_img_url: false,
                profile: false,
            },
            start: 0,
        }

        this.refreshTable = this.refreshTable.bind(this);

    }

    onVirtualTeacherInfo(user) {
        console.log(user);
        this.setState({
            userInfo: {
                uid: user.uid ?? 0,
                name: user.name ?? "",
                voice: user.voice ?? "",
                face: user.face ?? "",
                gender: user.gender ?? "m",
                age: user.age ?? "10",
                accent: user.accent ?? "",
                bg_url: user.bg_url ?? "",
                video_url: user.video_url ?? "",
                bg_img_url: user.bg_img_url ?? "",
                profile: user.profile ?? "",
            },
            error: {
                name: false,
                voice: false,
                face: false,
                gender: false,
                age: false,
                accent: false,
                bg_url: false,
                video_url: false,
                bg_img_url: false,
                profile: false,
            },
            start: 0,
        })
        this.setState({userModal: !this.state.userModal})
    }

    onDeleteVirtualTeacher(uid) {
        var self = this;
        this.context.showConfirm(strings.msg_confirm_delete, function (response) {
            if (response) {
                self.context.post("/deleteVirtualTeacher", {
                    uid: uid
                }, function (response) {
                    self.context.showToast("success", strings.msg_operation_success);

                    self.refreshTable();
                }, true);
            }
        })
    }


    onSaveVirtualTeacher() {
        const {userInfo} = this.state;
        const {error} = this.state;
        var self = this;

        error.name = userInfo.name == "";
        error.voice = userInfo.voice == "";
        error.face = userInfo.face == "";
        error.gender = userInfo.gender == "";
        error.age = userInfo.age == "";
        error.accent = userInfo.accent == "";
        error.bg_url = userInfo.bg_url == "";
        error.video_url = userInfo.video_url == "";
        error.bg_img_url = userInfo.bg_img_url == "";
        error.profile = userInfo.profile == "";

        self.setState({
            error: error
        });

        if (error.name || error.voice || error.face || error.gender || error.age || error.accent || error.bg_url || error.video_url || error.bg_img_url || error.profile) {
            return;
        }

        const profileUrl = userInfo.profile;
        userInfo.profile = userInfo.profile.split("/").pop();
        this.context.post("/saveVirtualTeacher", {
            userInfo
        }, function (response) {
            self.context.showToast("success", strings.msg_operation_success);

            self.refreshTable();
            self.setState({
                userModal: false
            })
        }, true);
        userInfo.profile = profileUrl;
    }

    refreshTable = (refresh) => {
        this.state.start = 0;
        this.tblUser.draw(refresh);
    };

    handleFormChange(event) {
        const target = event.target;
        const name = target.name;

        const {userInfo} = this.state;
        const {error} = this.state;

        userInfo[name] = target.value
        error[name] = target.value == ""

        this.setState({
            userInfo: userInfo,
            error: error
        })
    }

    onProfileUpload(e) {
        const self = this;
        const files = e.target.files[0];

        const {userInfo} = self.state;
        const {error} = this.state;

        let formData = new FormData();

        // formData.append("file", new Blob([files], {type: files.type}));
        formData.append("uploadFile", files);

        self.context.post("/upload", formData, function (response) {
            userInfo.profile = response.data[0];
            error.profile = false;
            self.setState({
                userInfo: userInfo,
                error: error
            });
        }, true);
    }

    render() {
        let self = this;
        let columnNo = 0;

        const {userInfo} = this.state;

        return (
            <div className="row m-t-20">
                <div className="col-md-12 text-right">
                    <button type="button" className="btn btn-primary m-r-5 m-b-5"
                            onClick={() => this.onVirtualTeacherInfo({})}>{strings.register}</button>
                </div>
                <div className="col-md-12">
                    <DataTable ref={(ref) => this.tblUser = ref} id="tblUser"
                               customPaginate={true}
                               initialPage={0}
                               hideTotalCount={true}
                               onPageChange={(value) => {
                                   self.state.start = value;
                                   this.refreshTable();
                               }}
                               options={{
                                   columnDefs: [
                                       {targets: [columnNo++], data: 'name', orderable: true,},
                                       {targets: [columnNo++], data: 'voice', orderable: true,},
                                       {targets: [columnNo++], data: 'face', orderable: false,},
                                       {
                                           targets: [columnNo++],
                                           data: 'gender',
                                           orderable: false,
                                           render: function (data, type, full, meta) {
                                               var res = "";
                                               if (data === "f") {
                                                   res = strings.female;
                                               } else {
                                                   res = strings.male;
                                               }
                                               return res;
                                           }
                                       },
                                       {targets: [columnNo++], data: 'age', orderable: true,},
                                       {targets: [columnNo++], data: 'accent', orderable: true,},
                                       {targets: [columnNo++], data: 'uid', orderable: false,}
                                   ],
                                   order: [[0, 'ASC']],
                                   ajax: {
                                       url: '/virtualTeacherTableList',
                                       data: function (req) {
                                           req.columns = null;
                                           req.start = self.state.start;
                                           req.length = 10;
                                       }
                                   },
                                   drawCallback: function () {
                                   },
                                   fnCreatedRow: function (row, data, index) {
                                       $('td:last', row).html(`<button type='button' class='btn btn-info btn-sm m-2' data-id="${index}" onclick="">${strings.edit}</button`);
                                       $('td:last', row).append(`<button type='button' class='btn btn-danger btn-sm m-2'>${strings.delete}</button`);

                                       $('td:last', row)
                                           .find('button:eq(0)')
                                           .on('click', function () {
                                               self.onVirtualTeacherInfo(data);
                                           });

                                       $('td:last', row)
                                           .find('button:eq(1)')
                                           .on('click', function () {
                                               self.onDeleteVirtualTeacher(data.uid);
                                           });
                                   }
                               }}>
                        <thead>
                        <tr>
                            <th>{strings.name}</th>
                            <th>{strings.voice}</th>
                            <th>{strings.face}</th>
                            <th>{strings.gender}</th>
                            <th>{strings.age}</th>
                            <th>{strings.accent}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody/>
                    </DataTable>
                </div>
                <Modal className="modal-lg" isOpen={this.state.userModal}
                       toggle={() => this.setState({userModal: !this.state.userModal})}>
                    <ModalHeader toggle={() => {
                        this.setState({userModal: !this.state.userModal})
                    }}>{strings.virtual_teacher_management}</ModalHeader>
                    <ModalBody>
                        <form className="form-horizontal">
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.name}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="name" type="text" required="required"
                                           onChange={(e) => this.handleFormChange(e)} defaultValue={userInfo.name}/>
                                    {this.state.error.name &&
                                    <p className="text-danger">{strings.msg_input_name}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.voice}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="voice" type="text" required="required"
                                           onChange={(e) => this.handleFormChange(e)} defaultValue={userInfo.voice}/>
                                    {this.state.error.voice &&
                                    <p className="text-danger">{strings.msg_input_voice}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.face}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="face" type="text" required="required"
                                           onChange={(e) => this.handleFormChange(e)} defaultValue={userInfo.face}/>
                                    {this.state.error.face &&
                                    <p className="text-danger">{strings.msg_input_face}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.gender}</label>
                                <div className="col-sm-9">
                                    <select className="form-control" name="gender" defaultValue={userInfo.gender}
                                            onChange={(e) => this.handleFormChange(e)}>
                                        <option value="m">{strings.male}</option>
                                        <option value="f">{strings.female}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.age}</label>
                                <div className="col-sm-9">
                                    <select className="form-control" name="age" defaultValue={userInfo.age}
                                            onChange={(e) => this.handleFormChange(e)}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="60">60</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.accent}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="accent" type="text" required="required"
                                           onChange={(e) => this.handleFormChange(e)} defaultValue={userInfo.accent}/>
                                    {this.state.error.accent &&
                                    <p className="text-danger">{strings.msg_input_accent}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.bg_url}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="bg_url" type="text" required="required"
                                           onChange={(e) => this.handleFormChange(e)} defaultValue={userInfo.bg_url}/>
                                    {this.state.error.bg_url &&
                                    <p className="text-danger">{strings.msg_input_bg_url}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.video_url}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="video_url" type="text" required="required"
                                           onChange={(e) => this.handleFormChange(e)}
                                           defaultValue={userInfo.video_url}/>
                                    {this.state.error.video_url &&
                                    <p className="text-danger">{strings.msg_input_video_url}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.bg_img_url}</label>
                                <div className="col-sm-9">
                                    <input className="form-control" name="bg_img_url" type="text" required="required"
                                           onChange={(e) => this.handleFormChange(e)}
                                           defaultValue={userInfo.bg_img_url}/>
                                    {this.state.error.bg_img_url &&
                                    <p className="text-danger">{strings.msg_input_bg_img_url}</p>
                                    }
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <label className="col-sm-3 col-form-label text-right">{strings.profile}</label>
                                <div className="col-sm-9">
                                    {userInfo.profile != "" &&
                                    <img src={userInfo.profile} width="100px" height="100px" className="m-b-15"
                                         style={{display: "block"}}/>}
                                    {this.state.error.profile &&
                                    <p className="text-danger">{strings.msg_upload_profile}</p>
                                    }
                                    <input type="file" id="fileUser" accept=".png,.jpg,.jpeg" ref={(fileUpload => {
                                        this.fileUpload = fileUpload;
                                    })} onChange={(e) => this.onProfileUpload(e)} hidden/>
                                    <button type="button" className="btn btn-success m-r-5 m-b-5"
                                            onClick={() => this.fileUpload.click()}>{strings.profile_upload}</button>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-white"
                                onClick={() => this.setState({userModal: !this.state.userModal})}>{strings.cancel}</button>
                        <button className="btn btn-primary"
                                onClick={() => this.onSaveVirtualTeacher()}>{strings.save}</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default VirtualTeacher;
